import api from '@/lib/api'

export default {
  createPayment (_, payment) {
    return api.post('/payments', { payment })
  },

  createPaymentBatch (_, { payment, count }) {
    return api.post('/payments/create_batch', { payment, count })
  },

  getPayment (_, paymentId) {
    return api.get(`/payments/${paymentId}`)
  },

  updatePayment (_, payment) {
    return api.put(`/payments/${payment.id}`, { payment })
  },

  removePayment (_, paymentId) {
    return api.delete(`/payments/${paymentId}`)
  },

  getPaymentOverview (_, filters) {
    return api.get('/payments/overview', { params: { ...filters } })
  }
}
