export default {
  setFilterBy (state, filterBy) {
    state.reports.filterBy = filterBy
  },

  setYearReference (state, yearReference) {
    state.reports.yearReference = yearReference
  },

  setMonthReference (state, monthReference) {
    state.reports.monthReference = monthReference
  }
}
