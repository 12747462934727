<template>
  <modal-dialog :show="show" @modal-close="close" title="Pagamentos">
    <template v-slot:content>
      <form @submit.prevent="handleSubmit">
        <div class="col-5 mb-5">
          <date-field
            label="Data prevista"
            v-model="payment.scheduledDate"
            data-test="payment-scheduled-date"
            :errorMessage="handleError($v)"
          />

          <input-field
            label="Valor previsto"
            v-model="payment.scheduledAmount"
            data-test="payment-scheduled-amount"
            type="number"
            :errorMessage="handleError($v)"
            step="0.01"
          />

          <date-field
            v-if="paymentReceived"
            label="Data de recebimento"
            v-model="payment.date"
            data-test="payment-date"
            :errorMessage="handleError($v)"
          />

          <input-field
            v-if="paymentReceived"
            label="Valor recebido"
            v-model="payment.amount"
            data-test="payment-amount"
            type="number"
            :errorMessage="handleError($v)"
            step="0.01"
          />

          <select-field
            label="Tipo de pagamento"
            v-model="payment.method"
            data-test="payment-method"
            :options="paymentMethods"
          />

          <select-field
            label="Status"
            v-model="payment.status"
            data-test="payment-status"
            :options="paymentStatusOptions"
          />
        </div>

        <div
          v-if="isUpdateForm() === false"
          class="d-flex justify-content-between"
        >
          <input-field
            label="Total"
            v-model="count"
            data-test="count"
            class="w-50"
          />

          <div class="form-check form-switch multiple-payments">
            <input
              v-model="multiplePayments"
              class="form-check-input"
              type="checkbox"
              id="flexSwitchCheckChecked"
            >
            <label class="form-check-label" for="flexSwitchCheckChecked">Multiplos pagamentos</label>
          </div>
        </div>

        <div v-if="budgetId !== undefined" class="d-flex justify-content-between flex-row-reverse">
          <form-button
            variant="primary"
            data-test="button-save"
            class="px-5"
          >Salvar</form-button>

          <button
            v-if="isUpdateForm()"
            @click="handleRemoval"
            class="btn remove-button"
            data-test="payment-delete-button"
            >Remover</button>
        </div>
      </form>
    </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/ModalDialog'
import InputField from '@/components/form/InputField'
import DateField from '@/components/form/DateField'
import SelectField from '@/components/form/SelectField'
import FormButton from '@/components/form/FormButton'
import formErrorHandler from '@/mixins/views/form-error-handler'
import { useVuelidate } from '@vuelidate/core'
import { paymentMethodsOptions } from '@/enums/payments'
import { paymentStatusOptions } from '@/enums/status'
import { required } from '@vuelidate/validators'
import { validDate } from '@/validators/index'
import { mapActions } from 'vuex'

export default {
  components: { ModalDialog, InputField, DateField, SelectField, FormButton },

  mixins: [formErrorHandler],

  props: {
    show: {
      type: Boolean,
      default: true
    },

    budgetId: {
      type: String,
      default: undefined
    },

    id: {
      type: String,
      default: undefined
    }
  },

  data () {
    return {
      count: 1,
      multiplePayments: false,
      payment: {
        scheduledDate: '',
        scheduledAmount: '',
        date: '',
        amount: '',
        method: '',
        status: ''
      },
      paymentMethods: paymentMethodsOptions(),
      paymentStatusOptions: paymentStatusOptions()
    }
  },

  setup: () => ({ $v: useVuelidate() }),

  validations () {
    const payment = {
      scheduledDate: { required, validDate },
      scheduledAmount: { required }
    }

    if (this.isUpdateForm() && this.payment.status === 'received') {
      payment.date = { required, validDate }
      payment.amount = { required }
    }

    return { payment }
  },

  async beforeMount () {
    if (this.isUpdateForm()) {
      this.fetchPayment()
    }
  },

  computed: {
    paymentReceived () {
      return this.payment.status === 'received'
    }
  },

  methods: {
    ...mapActions([
      'createPayment',
      'createPaymentBatch',
      'getBudget',
      'getPayment',
      'updatePayment',
      'removePayment'
    ]),

    isUpdateForm () {
      return this.id !== undefined
    },

    handleRequestMethod () {
      if (this.isUpdateForm()) { return this.updatePayment(this.payload()) }
      if (this.multiplePayments) {
        return this.createPaymentBatch({
          payment: this.payload(),
          count: this.count
        })
      }

      return this.createPayment(this.payload())
    },

    successMessage () {
      if (this.isUpdateForm()) { return 'Pagamento alterado com sucesso' }

      return 'Pagamento cadastrado com sucesso'
    },

    async handleSubmit () {
      const isValid = await this.$v.$validate()
      if (!isValid) { return }

      try {
        await this.handleRequestMethod()
        this.$toast.success(this.successMessage())
        this.close()
        this.getBudget(this.budgetId)
      } catch (error) {
        this.handleResponseError(error)
      }
    },

    payload () {
      return {
        id: this.payment.id,
        scheduled_date: this.payment.scheduledDate,
        scheduled_amount: this.payment.scheduledAmount,
        date: this.payment.date,
        amount: this.payment.amount,
        payment_method: this.payment.method,
        status: this.payment.status,
        budget_id: this.budgetId
      }
    },

    async fetchPayment () {
      try {
        const { data } = await this.getPayment(this.id)
        this.payment = {
          id: data.payment.id,
          scheduledDate: data.payment.scheduled_date,
          scheduledAmount: data.payment.scheduled_amount?.toFixed(2),
          date: data.payment.date,
          amount: data.payment.amount?.toFixed(2),
          method: data.payment.method,
          status: data.payment.status
        }
      } catch (error) {
        this.$toast.error('Houve algo de errado, por favor tente novamente mais tarde.')
      }
    },

    async handleRemoval () {
      if (confirm('Tem certeza?')) {
        try {
          await this.removePayment(this.payment.id)
          this.$toast.success('Pagamento removido com sucesso.')
          this.close()
          this.getBudget(this.budgetId)
        } catch (error) {
          this.$toast.error('Houve algo de errado, por favor tente novamente mais tarde.')
        }
      }
    },

    close () {
      this.$emit('payment-upsert-close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/constants.scss';
.remove-button {
  color: white !important;
  background-color: $danger-color !important;
  &:hover {
    background-color: $danger-dark-color !important;
  }
  &:disabled {
    color: rgba($color: white, $alpha: 0.6);
  }
}

.multiple-payments {
  padding-top: 7%;
}
</style>
