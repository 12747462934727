<template>
  <main>
    <user-navbar class="mb-5" />

    <user-alerts class="mb-3"/>

    <router-view />

    <kanban-board />
  </main>
</template>

<script>
import UserNavbar from '@/components/UserNavbar.vue'
import KanbanBoard from '@/components/kanban/KanbanBoard.vue'
import UserAlerts from '@/components/alerts/UserAlerts.vue'

export default {
  components: {
    KanbanBoard,
    UserAlerts,
    UserNavbar
  }
}
</script>
