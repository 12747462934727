<template>
  <main class="product-list-container">
    <div class="product-list">
      <div class="product-list__card-list">
        <div v-if="showCreateProductButton" class="product-list__new-product-link">
          <a @click="toggleProductUpsert">
            <i class="bi-clipboard-data" />
            <br />Adicionar novo serviço
          </a>
        </div>
        <product-card
          v-for="product in products"
          :key="product.id"
          :product="product"
          class="product-list__card"
          @product-modal-open="showProduct"
        />
      </div>
    </div>

    <product-upsert
      v-if="productUpsertModal.isVisible"
      :show="productUpsertModal.isVisible"
      :id="productUpsertModal.productId"
      :budgetId="budgetId"
      @product-upsert-close="toggleProductUpsert"
    />
  </main>
</template>

<script>
import ProductCard from '@/components/ProductCard'
import ProductUpsert from '@/views/private/products/ProductUpsert'
export default {
  components: {
    ProductCard,
    ProductUpsert
  },

  props: {
    products: {
      type: Array,
      required: true
    },

    showCreateProductButton: {
      type: Boolean,
      default: false
    },

    budgetId: {
      type: String,
      default: undefined
    }
  },

  data () {
    return {
      productUpsertModal: {
        productId: undefined,
        isVisible: false
      }
    }
  },

  methods: {
    toggleProductUpsert () {
      this.productUpsertModal.productId = undefined
      this.productUpsertModal.isVisible = !this.productUpsertModal.isVisible
    },

    showProduct ({ productId }) {
      this.toggleProductUpsert()
      this.productUpsertModal.productId = productId
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/styles/constants.scss';
@import '@/styles/card.scss';

.product-list {
  &__card-list {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: repeat(auto-fill, minmax($card-list-column-min, $card-list-column-max));
      grid-gap: $card-list-grid-gap;
      margin: $card-list-margin;
      justify-items: center;
  }

  &__card {
    width: 100%;
    align-self: center;
    justify-self: center;
  }

  &__new-product-link {
    @extend .card;
    background-color: transparent;

    a {
      font-size: 1.75em;
      color: $gray-lighter-color !important;
      text-align: center;
      text-decoration: none;
      padding: 1em;
      cursor: pointer;

      i {
        font-size: 2em;
      }
    }

    a:hover {
      color: $secondary-color !important;
    }
  }
}
</style>
