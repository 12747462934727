<template>
  <div class="payment-card" data-test="payment-card">
    <span class="payment-card__code">#{{ payment.code + 1 }}</span>

    <rounded-badge
      :variant="paymentStatusColor"
      class="payment-card__status"
      data-test="payment-status"
    >
      {{ paymentStatusText }}
    </rounded-badge>

    <div class="payment-card__date">
      <span class="label">Recebimento:</span>
      <span data-test="payment-date"> {{ payment.date || payment.scheduled_date }} </span>
    </div>

    <div class="payment-card__amount">
      <span class="label">Valor:</span>
      <span data-test="payment-amount">R$ {{  formatMoney(payment.amount) || formatMoney(payment.scheduled_amount) }}</span>
    </div>

    <div @click="showPayment" class="payment-card__link">
      ver mais <i class="bi-chevron-right"></i>
    </div>
  </div>
</template>

<script>
import RoundedBadge from '@/components/RoundedBadge'

export default {
  components: {
    RoundedBadge
  },

  props: {
    payment: {
      type: Object,
      required: true
    }
  },

  computed: {
    paymentStatusColor () {
      const status = {
        pending: 'danger',
        received: 'primary'
      }

      return status[this.payment.status]
    },

    paymentStatusText () {
      const status = {
        pending: 'Não recebido',
        received: 'Recebido'
      }

      return status[this.payment.status]
    }
  },

  methods: {
    showPayment () {
      this.$emit('payment-modal-open', { paymentId: this.payment.id })
    },

    formattedAmount () {
      return this.payment.amount?.toFixed(2)
    },

    formattedScheduledAmount () {
      return this.payment.scheduled_amount?.toFixed(2)
    },

    formatMoney (field) {
      return field?.toLocaleString('pt-BR', { minimumFractionDigits: 2 })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/constants.scss';
@import '@/styles/card.scss';

.payment-card {
  @extend .card;
  &__code {
    font-weight: bolder;
    text-align: center;
    width: 100%;
  }

  &__status {
    font-size: $card-status-fs;
  }

  &__date,
  &__amount {
    display: flex;
    flex-direction: column;
    align-items: center;

    .label {
      font-weight: 300;
    }
  }

  &__link {
    color: $secondary-color;
    text-decoration: none;
    cursor: pointer;
  }

  &__link:hover {
    color: $secondary-dark-color;
  }

}
</style>
