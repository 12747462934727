const budgetStatusOptions = () => {
  return [
    {
      value: 'negotiation',
      label: 'Em negociação'
    },
    {
      value: 'approved',
      label: 'Aprovado'
    },
    {
      value: 'rejected',
      label: 'Reprovado'
    }
  ]
}

const projectStatusOptions = () => {
  return [
    {
      value: 'pending',
      label: 'Não iniciado'
    },
    {
      value: 'started',
      label: 'Em andamento'
    },
    {
      value: 'finished',
      label: 'Finalizado'
    }
  ]
}

const productStatusOptions = () => {
  return [
    {
      value: 'pending',
      label: 'Não iniciado'
    },
    {
      value: 'started',
      label: 'Em andamento'
    },
    {
      value: 'finished',
      label: 'Finalizado'
    }
  ]
}

const paymentStatusOptions = () => {
  return [
    {
      value: 'pending',
      label: 'Aguardando pagamento'
    },
    {
      value: 'received',
      label: 'Recebido'
    }
  ]
}

export {
  budgetStatusOptions,
  projectStatusOptions,
  productStatusOptions,
  paymentStatusOptions
}
