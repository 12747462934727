<template>
  <nav class="navbar navbar-expand-lg navbar-light user-navbar">
    <div class="container-fluid">
      <router-link :to="{name: 'home'}" class="navbar-brand">
        <img src="@/assets/logo-aacet.png" width="30" height="30" />
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link
              :to="{ name: 'project-list' }"
              class="nav-link"
              :class="selectedTab('projects')"
              data-test="project-tab"
            >
              <i class="bi-stack" /> Projetos
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'client-list' }"
              class="nav-link"
              :class="selectedTab('clients')"
              data-test="clients-tab"
            >
              <i class="bi-people" /> Clientes
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'user-list' }"
              class="nav-link"
              :class="selectedTab('users')"
              data-test="users-tab"
            >
              <i class="bi-person" /> Usuários
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'financial-home' }"
              :class="selectedTab('financial')"
              class="nav-link">
              <i class="bi-cash-stack" /> Administrativo
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'category-list' }"
              :class="selectedTab('categories')"
              class="nav-link">
              <i class="bi-gear" /> Categorias
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'reports' }"
              :class="selectedTab('reports')"
              class="nav-link">
              <i class="bi-bar-chart" /> Relatórios
            </router-link>
          </li>
        </ul>
        <div class="ms-auto">
          <span
            class="me-2"
            data-test="current-user"
          >
            <i class="bi-person" /> {{ currentUser }}
          </span>
          <button
            @click="handleLogout"
            class="btn btn-outline-dark"
            data-test="logout"
          >
            Sair
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data () {
    return {
      currentTab: this.$route?.meta?.currentTab
    }
  },

  computed: {
    currentUser () {
      return localStorage.getItem('userEmail')
    }
  },

  methods: {
    handleLogout () {
      localStorage.clear()
      this.$router.push({ name: 'new-session' })
    },

    selectedTab (tab) {
      if (tab === this.currentTab) { return 'user-navbar__selected-tab' }

      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/constants.scss';

  .user-navbar {
    background-color: white !important;

    &__selected-tab {
      border-bottom: 2px solid $secondary-color;
      color: $secondary-color !important;

      > * {
        color: $secondary-color;
      }
    }
  }
</style>
