<template>
  <div class="user-card">
    <span
      class="user-card__name"
      data-test="user-name"
    >
      {{ firstAndLastName }}
    </span>

    <rounded-badge
      :variant="userKindColor"
      class="user-card__kind"
      data-test="user-kind"
    >
      {{ userKindText }}
    </rounded-badge>

    <span class="user-card__email">
      {{ user.email }}
    </span>

    <router-link :to="{ name: 'user-show', params: { id: user.id } }" class="user-card__link">
      ver mais <i class="bi-chevron-right" />
    </router-link>
  </div>
</template>

<script>
import RoundedBadge from '@/components/RoundedBadge.vue'

export default {
  components: {
    RoundedBadge
  },

  props: {
    user: {
      type: Object,
      require: true
    }
  },

  computed: {
    userKindColor () {
      if (this.user.kind === 'admin') { return 'primary' }

      return 'secondary'
    },

    userKindText () {
      if (this.user.kind === 'admin') { return 'Administrador' }

      return 'Colaborador'
    },

    firstAndLastName () {
      const userSplittedNames = this.user.name.split(' ')
      const firstName = userSplittedNames[0]
      const lastName = userSplittedNames[userSplittedNames.length - 1]
      return `${firstName} ${lastName}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/constants.scss';
@import '@/styles/card.scss';

  .user-card {
    @extend .card;

    &__name {
      font-weight: bolder;
      text-align: center;
      width: 100%;
    }

    &__kind {
      font-size: 0.8em;
    }

    &__email {
      margin-bottom: 2em;
    }

    &__link {
      color: $secondary-color;
      text-decoration: none;
    }

    &__link:hover {
        color: $secondary-dark-color;
      }
  }
</style>
