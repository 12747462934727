<template>
  <div class="project-card">
    <span class="project-card__code">{{ project.code }}</span>

    <rounded-badge
      :variant="projectStatusColor"
      class="project-card__kind"
      data-test="project-status"
    >
      {{ projectStatusText }}
    </rounded-badge>

    <span class="project-card__client">{{ project.client.name }}</span>

    <router-link :to="{ name: 'project-show', params: { id: project.id } }" class="project-card__link">
      ver mais <i class="bi-chevron-right"></i>
    </router-link>
  </div>
</template>

<script>
import RoundedBadge from '@/components/RoundedBadge'

export default {
  components: {
    RoundedBadge
  },

  props: {
    project: {
      type: Object,
      required: true
    }
  },

  computed: {
    projectStatusColor () {
      const status = {
        pending: 'danger',
        started: 'secondary',
        finished: 'primary'
      }

      return status[this.project.status]
    },

    projectStatusText () {
      const status = {
        pending: 'Não iniciado',
        started: 'Iniciado',
        finished: 'Concluído'
      }

      return status[this.project.status]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/constants.scss';
@import '@/styles/card.scss';

  .project-card {
    @extend .card;

    &__name {
      font-weight: bolder;
      text-align: center;
      width: 100%;
    }

    &__kind {
      font-size: 0.8em;
    }

    &__company {
      margin-bottom: 2em;
    }

    &__link {
      color: $secondary-color;
      text-decoration: none;
    }

    &__link:hover {
        color: $secondary-dark-color;
    }
  }
</style>
