<template>
    <div class="category-card">
    <span class="category-card__title">{{ category.title }}</span>
    <router-link
      :to="{ name: actionLink, params: { id: category.id } }"
      class="category-card__link">
      ver mais <i class="bi-chevron-right" />
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    category: {
      type: Object,
      required: true
    },

    actionLink: {
      type: String,
      default: 'category-show'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/constants.scss';
@import '@/styles/card.scss';

  .category-card {
    @extend .card;
    &__title {
      font-weight: bolder;
      text-align: center;
      width: 100%;
    }

    &__kind {
      font-size: 0.8em;
    }

    &__company {
      margin-bottom: 2em;
    }

    &__link {
      color: $secondary-color;
      text-decoration: none;
    }

    &__link:hover {
        color: $secondary-dark-color;
      }
  }
</style>
