import Vue from 'vue'
import Vuex from 'vuex'
import userStore from './users'
import addressStore from './adresses'
import clientStore from './clients'
import projectStore from './projects'
import budgetStore from './budgets'
import categoryStore from './categories'
import subcategoryStore from './subcategories'
import productsStore from './products'
import alertsStore from './alerts'
import paymentStore from './payments'
import reportsStore from './reports'

Vue.use(Vuex)

const state = {
  ...budgetStore.state,
  ...reportsStore.state
}

const mutations = {
  ...budgetStore.mutations,
  ...reportsStore.mutations
}

const actions = {
  ...userStore.actions,
  ...addressStore.actions,
  ...clientStore.actions,
  ...projectStore.actions,
  ...budgetStore.actions,
  ...categoryStore.actions,
  ...productsStore.actions,
  ...subcategoryStore.actions,
  ...alertsStore.actions,
  ...paymentStore.actions,
  ...reportsStore.actions
}

const getters = {
  ...reportsStore.getters
}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
  }
})
