<template>
  <div class="budget-revisions-table">
    <div class="budget-revisions-table__header my-3">
      <h3>Revisões</h3>
      <button v-if="!viewOnly" class="btn action-button mx-2" @click="handleGenerate()" data-test="generate-revision-button">Gerar revisão</button>
    </div>

    <table-items
      :fields='["Data de início", "Valor"]'
      :hasRowNumber="true"
      :items="revisions"
    >
      <slot slot-scope="{ row }">
        <td data-test="revision-start-date">{{ row.start_date }} </td>
        <td data-test="revision-estimated-amount">
          R$ {{ formatMoney(row.estimated_amount )}}
        </td>
        <td>
          <router-link :to="{ name: 'budget-edit', params: { id: row.id, revision: true } }" class="item__link" data-test="revision-budget-link">
            ver mais <i class="bi-chevron-right" />
          </router-link>
        </td>
      </slot>

    </table-items>

  </div>
</template>

<script>
import TableItems from '@/components/TableItems'
import { mapActions } from 'vuex'

export default {
  props: {
    budgetId: {
      type: String,
      required: true
    },

    revisions: {
      type: Array,
      required: true
    },

    viewOnly: {
      type: Boolean,
      default: false
    }
  },

  components: { TableItems },

  methods: {
    ...mapActions(['getBudget', 'generateRevision']),

    async handleGenerate () {
      if (confirm('Deseja gerar um novo orçamento?')) {
        try {
          const budgetId = this.budgetId
          const { data } = await this.generateRevision(budgetId)
          await this.getBudget(data.id)
          this.$toast.success('A nova versão do orçamento foi gerado com sucesso.')
          this.$router.push({ name: 'budget-edit', params: { id: data.id } })
        } catch (error) {
          console.error(error)
          this.$toast.error('Houve algo de errado, por favor tente novamente mais tarde.')
        }
      }
    },

    formatMoney (field) {
      return field.toLocaleString('pt-BR', { minimumFractionDigits: 2 })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'bootstrap/dist/css/bootstrap';
@import '@/styles/constants.scss';
.budget-revisions-table {
  .budget-revisions-table__header {
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
  }

  .action-button {
    color: white !important;
    background-color: $primary-color !important;
    &:hover {
      background-color: $primary-dark-color !important;
    }
    &:disabled {
      color: rgba($color: white, $alpha: 0.6);
    }
  }

}
</style>
