<template>
  <main class="new-session">
    <div class="new-session__card">
      <div class="new-session__header">
        <div>
          <h2>Seja bem vindo!</h2>
          <h6>Entre com suas credenciais</h6>
        </div>
        <img src="@/assets/logo-aacet.png" />
      </div>
      <div>
        <form @submit.prevent="handleSubmit">
          <input-field
            label="Email"
            type="email"
            v-model="email"
            :errorMessage="handleError(this.$v)"
            data-test="email"
          />

          <input-field
            label="Senha"
            type="password"
            v-model="password"
            :errorMessage="handleError(this.$v)"
            data-test="password"
          />

          <form-button
            variant="secondary"
            data-test="form-button"
          >
            Entrar
          </form-button>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import InputField from '@/components/form/InputField'
import FormButton from '@/components/form/FormButton.vue'
import { email, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import formErrorHandler from '@/mixins/views/form-error-handler'
import { mapActions } from 'vuex'
/* eslint-disable camelcase */
import jwt_decode from 'jwt-decode'
/* eslint-enable camelcase */

export default {
  components: {
    InputField,
    FormButton
  },

  mixins: [formErrorHandler],

  data () {
    return {
      email: '',
      password: ''
    }
  },

  setup: () => ({ $v: useVuelidate() }),

  validations () {
    return {
      email: { required, email },
      password: { required }
    }
  },

  methods: {
    ...mapActions(['authenticateUser']),

    async handleSubmit () {
      const isValid = await this.$v.$validate()
      if (!isValid) { return }

      const user = { email: this.email, password: this.password }
      this.authenticateUser(user)
        .then(response => {
          const authToken = response.data.token
          const decodedToken = jwt_decode(authToken)
          localStorage.setItem('userId', decodedToken.user_id)
          localStorage.setItem('userEmail', decodedToken.email)
          localStorage.setItem('authToken', authToken)
          this.$router.push({ path: '/' })
        })
        .catch(error => this.handleResponseError(error))
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'bootstrap/dist/css/bootstrap';
@import '@/styles/constants.scss';

  .new-session {
    &__header {
      display: flex;
      justify-content: space-between;

      h2 {
        font-weight: 400;
      }

      h6 {
        color: $gray-light-color;
        font-weight: 300;
      }

      img {
        width: 15%;
        height: 15%;
      }
    }

    &__card {
      @extend .card;
      @extend .col-3;
      padding: 1.5em;
      margin-left: auto;
      margin-right: auto;
      top: 20%;
    }
  }
</style>
