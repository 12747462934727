export default function buildAlert (params) {
  return {
    id: params.id || '',
    kind: params.kind || '',
    project: {
      id: params?.project?.id || '',
      code: params?.project?.code || ''
    },
    referenceDate: params.reference_date || '',

    get formattedReferenceDate () {
      if (this.referenceDate === '') { return '' }

      const date = this.referenceDate.split('T')[0].split('-')
      return `${date[2]}/${date[1]}/${date[0]}`
    },

    get translatedKind () {
      if (this.kind === '') { return '' }
      if (this.kind === 'project_pending') { return 'Projeto parado' }
      if (this.kind === 'payment_close') { return 'Pagamento próximo' }
      if (this.kind === 'payment_overdue') { return 'Pagamento vencido' }

      return ''
    }
  }
}
