import api from '@/lib/api'

export default {
  authenticateUser (_, user) {
    return api.post('/authenticate', { user })
  },

  createUser (_, user) {
    return api.post('/users', { user })
  },

  listUser (_, pageIndex) {
    return api.get(`/users?page=${pageIndex}`)
  },

  allUsers (_) {
    return api.get('/users?all=true')
  },

  getUser (_, userId) {
    return api.get(`/users/${userId}`)
  },

  updateUser (_, user) {
    return api.put(`/users/${user.id}`, { user })
  },

  removeUser (_, userId) {
    return api.delete(`/users/${userId}`)
  }
}
