<template>
  <main>
    <user-navbar />
    <div class="financial-home">
      <h3 class="mb-3">Financeiro</h3>
      <payments-overview data-test="payments-overview" />
    </div>
  </main>
</template>

<script>
import UserNavbar from '@/components/UserNavbar'
import PaymentsOverview from '@/components/financial/PaymentsOverview'

export default {
  components: { UserNavbar, PaymentsOverview },

  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/constants.scss";
.financial-home {
  padding: $form-page-padding;
}

</style>
