<template>
  <main>
    <user-navbar />
    <div class="client-show">
      <div class="d-flex justify-content-between mb-3">
        <h3 class="mb-3">Clientes</h3>

        <div class="action-buttons">
          <router-link
            class="action-button edit mx-2"
            :to="{ name: 'client-edit', params: { id: id } }"
          >
            <i class="bi-pencil-square" />
          </router-link>
          <button class="action-button remove" @click="handleRemoval">
            <i class="bi-trash" />
          </button>
        </div>

        </div>
      <div class="general-info">
        <h5>Dados Gerais</h5>
        <div class="content">
            <div class="column">
              <div class="info-item">
                <label>Nome:</label>
                <span data-test="client-name">{{ client.name }}</span>
              </div>

              <div class="info-item">
                <label>Instituição:</label>
                <span data-test="client-institution">{{ client.institution }} - {{ institutionKind }}</span>
              </div>

              <div class="info-item">
                <label>Cargo:</label>
                <span data-test="client-position-kind">{{ positionKind }}</span>
              </div>

              <div class="info-item">
                <label>E-mail:</label>
                <span data-test="client-email">{{ client.email }}</span>
              </div>

              <div class="info-item">
                <label>Telefone:</label>
                <span data-test="client-phone">{{ client.phone }}</span>
              </div>

              <div class="info-item">
                <label>Midias sociais:</label>
                <span data-test="client-social-media">{{ client.contact.social_media }}</span>
              </div>

              <div class="info-item">
                <label>Website:</label>
                <span data-test="client-website">{{ client.contact.website }}</span>
              </div>

              <div class="info-item">
                <label>Responsáveis:</label><br/>
                <form-tag
                  v-for="item in client.users"
                  :value="item.user.id"
                  :key="item.user.id"
                  class="me-2 mt-2"
                  data-test="client-users"
                >
                  {{ item.user.name }}
                </form-tag>
              </div>
            </div>

            <div class="column">
              <div class="info-item">
                <label>Lougradouro:</label>
                <span data-test="client-complement">{{ client.address.complement }}</span>
              </div>

              <div  v-if="!client.address.international" class="info-item">
                <label>Cidade:</label>
                <span data-test="client-city">{{ client.address.city.name }}</span>
              </div>

              <div v-if="!client.address.international" class="info-item">
                <label>Estado:</label>
                <span data-test="client-state">{{ client.address.state.name }}</span>
              </div>

              <div class="info-item">
                <label>País:</label>
                <span data-test="client-country">{{ client.address.country }}</span>
              </div>

              <div class="info-item">
                <label>Mais informações:</label>
                <span data-test="client-more-info">{{ client.contact.more_info }}</span>
              </div>
            </div>
        </div>
      </div>

      <div class="client-show__project-list mb-5">
       <h4 class="mb-3">Projetos</h4>

       <div class="project-list__card-list">
        <project-card
          v-for="project in client.projects"
          :key="project.id"
          :project="project"
          class="project-list__card"
          />
       </div>
      </div>

      <payments-overview :clientId="id" />
    </div>
  </main>
</template>

<script>
import UserNavbar from '@/components/UserNavbar'
import FormTag from '@/components/form/FormTag'
import ProjectCard from '@/components/ProjectCard'
import PaymentsOverview from '@/components/financial/PaymentsOverview'

export default {
  components: {
    UserNavbar,
    FormTag,
    ProjectCard,
    PaymentsOverview
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      client: {
        name: '',
        position: '',
        phone: '',
        email: '',
        institution: '',
        address: {
          complement: '',
          cep: '',
          international: false,
          country: '',
          city: {
            id: '',
            name: ''
          },
          state: {
            id: '1',
            name: 'Goiás',
            uf: 'GO'
          }
        },
        contact: {
          more_info: '',
          social_media: '',
          website: ''
        },
        users: [],
        projects: []
      }
    }
  },
  mounted () {
    this.$store
      .dispatch('getClient', this.id)
      .then(({ data }) => {
        this.client = data.client
        this.client.projects = data.client.projects.map(p => p.project)
      })
      .catch((_) => {
        this.$toast.error(
          'Houve algo de errado, por favor tente novamente mais tarde.'
        )
      })
  },

  computed: {
    institutionKind () {
      if (this.client.kind === 'company') return 'Empresa'

      return 'Universidade'
    },

    positionKind () {
      const kinds = {
        bachelors_student_tcc: 'Aluno de Graduação - TCC',
        bachelors_student_ic: 'Aluno de Graduação - Iniciação Científica',
        masters_student: 'Aluno de Mestrado',
        residency_student: 'Aluno de Residência',
        doctorate_student: 'Aluno de Doutorado',
        university_professor: 'Professor de Universidade',
        company_colaborator: 'Colaborador - Empresa',
        company_owner: 'Proprietário - Empresa',
        company_partner: 'Sócio - Empresa'
      }

      const position = (kinds[this.client.position]) ? kinds[this.client.position] : ''

      return position
    }
  },

  methods: {
    handleRemoval () {
      if (confirm('Tem certeza?')) {
        this.$store
          .dispatch('removeClient', this.id)
          .then((_) => {
            this.$toast.success('Cliente removido com sucesso.')
            this.$router.push({ name: 'client-list' })
          })
          .catch((_) => {
            this.$toast.error(
              'Houve algo de errado, por favor tente novamente mais tarde.'
            )
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "bootstrap/dist/css/bootstrap";
@import "@/styles/constants.scss";
@import "@/styles/buttons.scss";

.client-show {
  padding: 2em;

  .action-button {
    @extend .btn;

    &.remove {
     @extend .danger-outline;
    }

    &.edit {
      @extend .secondary-outline
   }
  }

  .general-info {
    background-color: #FFFF;
    padding: 20px 30px;
    margin-bottom: 30px;

    .content {
      padding: 10px;
      display: flex;
      justify-content: space-between;

      .column {
        width: 90%;
      }
    }
  }

  .info-item {
    margin-bottom: 20px;
    font-weight: 300;

    label {
      font-weight: 500;
      margin-right: 5px;
    }
  }

  .project-list {
    &__card-list {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: repeat(auto-fill, minmax($card-list-column-min, $card-list-column-max));
      grid-gap: $card-list-grid-gap;
      margin: $card-list-margin-sm;
      justify-items: center;
    }

    &__card {
      width: 100%;
      align-self: center;
      justify-self: center;
    }
  }
}
</style>
