<template>
  <button
    class="alerts-button"
    title="Alertas"
    @click="$emit('open-alerts-modal')"
  >
    <i class="bi-bell-fill" />
    <span
      class="alerts-button__count"
      data-test="alerts-count"
    >
      {{ alertsCount }}
    </span>
  </button>
</template>

<script>
export default {
  name: 'AlertsButton',

  props: {
    alertsCount: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/constants.scss';
@import "bootstrap/dist/css/bootstrap";

.alerts-button {
  @extend .btn, .text-white;
  background-color: $primary-dark-color;

  &__count {
    @extend .ms-2;
  }
}

</style>
