<template>
  <main>
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h3>Serviços por cliente</h3>
        <tool-tip icon="bi-info-circle" text="Serviços agrupados pelo perfil do cliente."/>
      </div>

      <div class="card-body">
        <canvas id="productsByClientPositionChart"></canvas>
      </div>
    </div>
  </main>
</template>

<script>
import { Chart, registerables } from 'chart.js'
import { mapActions, mapGetters } from 'vuex'
import ToolTip from '@/components/Tooltip'
import loadErrorHandler from '@/mixins/views/load-error-handler'

const BACKGROUND_COLORS = [
  '#ADBF00', '#35BCC2', '#FC7676', '#113945', '#60840f',
  '#cad099', '#51B7C7', '#163f49', '#FC7676', '#3F404A'
]
const CHART_TYPE = 'bar'

export default {
  name: 'ProductsByClientPosition',

  components: {
    ToolTip
  },

  mixins: [loadErrorHandler],

  data () {
    return {
      labels: [],
      chartData: {},
      chart: null
    }
  },

  mounted () {
    Chart.register(...registerables)
    this.handleFilterChange()
  },

  computed: {
    chartOptions () {
      return {
        scales: {
          x: { stacked: false },
          y: { beginAtZero: true, stacked: false }
        },
        plugins: {
          legend: {
            labels: {
              generateLabels: (chart) => {
                return chart.data.labels.map(
                  (label, index) => ({
                    text: label,
                    fillStyle: chart.data.datasets[0].backgroundColor[index],
                    strokeStyle: chart.data.datasets[0].backgroundColor[index]
                  })
                )
              }
            }
          }
        }
      }
    },

    filterBy () {
      return this.getFilterBy()
    },

    monthReference () {
      return this.getMonthReference()
    },

    yearReference () {
      return this.getYearReference()
    },

    canvasElement () {
      return document.getElementById('productsByClientPositionChart')
    },

    changedFilter () {
      return {
        monthReference: this.monthReference,
        yearReference: this.yearReference
      }
    }
  },

  methods: {
    ...mapActions(['getProductsByClientPositionReport']),
    ...mapGetters(['getFilterBy', 'getYearReference', 'getMonthReference']),
    datasetBuilder (dataClass) {
      return {
        label: dataClass.label,
        data: this[dataClass.attribute],
        borderWidth: 1,
        backgroundColor: dataClass.color
      }
    },

    handleReturnedData (data) {
      this.labels = data.group_labels
      this.chartData = data.report_data
    },

    handleFilterChange () {
      if (this.getFilterBy() !== 'general' && this.yearReference === null) {
        this.$toast.warning('Selecione o mês e ano para que o filtro seja aplicado')
        return
      }

      this.getProductsByClientPositionReport({
        filterBy: this.filterBy,
        yearReference: this.yearReference,
        monthReference: this.monthReference
      })
        .then(response => {
          this.handleReturnedData(response.data)

          this.chart?.destroy()

          /* eslint-disable no-new */
          this.chart = new Chart(this.canvasElement, {
            type: CHART_TYPE,
            data: {
              labels: this.labels,
              datasets: [
                {
                  data: this.chartData,
                  backgroundColor: BACKGROUND_COLORS
                }
              ]
            },
            options: this.chartOptions
          })
          /* eslint-enable no-new */
        }).catch(error => this.handleLoadError(error))
    }
  },

  watch: {
    changedFilter (_oldValue, _newValue) {
      this.handleFilterChange()
    }
  }
}
</script>
