<template>
  <div class="client-card">
    <span class="client-card__name">{{ client.name }}</span>

    <rounded-badge
      :variant="clientKindColor"
      class="client-card__kind"
    >
      {{ clientKindText }}
    </rounded-badge>

    <span class="client-card__company">{{ client.institution }}</span>

    <router-link :to="{ name: 'client-show', params: { id: client.id } }" class="client-card__link">
      ver mais <i class="bi-chevron-right" />
    </router-link>
  </div>
</template>

<script>
import RoundedBadge from '@/components/RoundedBadge'

export default {
  components: {
    RoundedBadge
  },

  props: {
    client: {
      type: Object,
      required: true
    }
  },

  computed: {
    clientKindColor () {
      if (this.client.kind === 'company') return 'primary'

      return 'secondary'
    },

    clientKindText () {
      if (this.client.kind === 'company') return 'Empresa'

      return 'Universidade'
    },

    firstAndLastName () {
      const clientSplittedNames = this.client.name.split(' ')
      const firstName = clientSplittedNames[0]
      const lastName = clientSplittedNames[clientSplittedNames.length - 1]
      return `${firstName} ${lastName}`
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/styles/constants.scss';
@import '@/styles/card.scss';

  .client-card {
    @extend .card;

    &__name {
      font-weight: bolder;
      text-align: center;
      width: 100%;
    }

    &__kind {
      font-size: 0.8em;
    }

    &__company {
      margin-bottom: 2em;
    }

    &__link {
      color: $secondary-color;
      text-decoration: none;
    }

    &__link:hover {
        color: $secondary-dark-color;
      }
  }
</style>
