<template>
  <div class="project-overview">
    <div class="overview">
      <span class="overview__title">Estimativa</span>
      <div class="overview__stats">
        <div class="card workload">
          <i class="bi-clock card__icon" />
          <br/>
          <span class="card__value" data-test="project-estimated-workload">{{ project.estimated_workload }}h</span>
          <br/>
          <span class="card__description">
            Total de horas
          </span>
        </div>
        <div class="card amount">
          <i class="bi-cash-coin card__icon" /><br/>
          <span class="card__value" data-test="project-estimated-amount">R$ {{ formatMoney(project.estimated_amount) }}</span>
          <br/>
          <span class="card__description">
            Custo total
          </span>
        </div>
      </div>
      <span class="overview__title">Valor final</span>
      <div class="overview__stats">
        <div class="card  workload">
          <i class="bi-clock card__icon" /><br/>
          <span class="card__value" data-test="project-workload">{{ project.workload }}h</span>
          <br/>
          <span class="card__description">
            Total de horas
          </span>
        </div>
        <div class="card amount">
          <i class="bi-cash-coin card__icon" /><br/>
          <span class="card__value" data-test="project-amount">R$ {{ formatMoney(project.amount) }}</span>
          <br/>
          <span class="card__description">
            Custo total
          </span>
        </div>
      </div>

      <span v-if="showDuration" class="overview__title">Tempo total</span>
      <div v-if="showDuration" class="overview__stats single-column">
        <div class="card workload">
          <i class="bi-calendar-check card__icon" /><br/>
          <span class="card__value" data-test="project-duration">{{ project.start_date }} - {{ project.finish_date }}</span>
          <br/>
          <span class="card__description">
            Duração do projeto
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    project: {
      type: Object,
      require: true
    },

    showDuration: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    formatMoney (field) {
      return field.toLocaleString('pt-BR', { minimumFractionDigits: 2 })
    }
  }
}
</script>

<style lang="scss" scoped>
  .project-overview {
    padding: 5px 10px;
  }

  .overview {
    display: flex;
    flex-direction: column;

    .overview__title {
      font-weight: 300;
    }
    .overview__stats {
      display: grid;
      grid-template-columns: minmax(150px, 1fr) minmax(150px, 1fr);
      gap: 10px 20px;
      justify-content: space-between;
      padding: 5px 10px;
      margin-bottom: 5px;

      &.single-column {
        grid-template-columns: minmax(150px, 1fr);
      }

      .card  {
        background-color: #fff;
        padding: 15px;
        display: flex;
        align-content: center;
        flex-direction: column;
        row-gap: 3px;
        border-radius: 5px;

        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        .card__icon {
          font-size: 1.3rem;
        }

        .card__value {
          font-size: 1.3rem;
        }

        .card__description {
          font-weight: 300;
          font-size: 0.9rem;
        }
      }
    }
  }
</style>
