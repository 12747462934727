<template>
  <main class="category-upsert">
    <user-navbar />
    <div class="category-upsert__form">
      <h2>Categoria</h2>

      <form @submit.prevent="handleSubmit">

        <div class="col-2 mb-5">
          <input-field
            label="Título"
            v-model="category.title"
            data-test="category-title"
            :errorMessage="handleError(this.$v)"
          />
        </div>

        <hr>

        <form-button
          variant="primary"
          data-test="form-button"
        >
          Salvar
        </form-button>
      </form>
    </div>
  </main>
</template>

<script>
import UserNavbar from '@/components/UserNavbar'
import InputField from '@/components/form/InputField'
import FormButton from '@/components/form/FormButton'
import formErrorHandler from '@/mixins/views/form-error-handler'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { mapActions } from 'vuex'

export default {
  components: { UserNavbar, InputField, FormButton },

  mixins: [formErrorHandler],

  props: {
    id: {
      type: String,
      default: undefined
    }
  },

  data () {
    return {
      category: {
        title: ''
      }
    }
  },

  setup: () => ({ $v: useVuelidate() }),

  validations () {
    return {
      category: {
        title: { required }
      }
    }
  },

  mounted () {
    if (this.isUpdateForm()) {
      this.fetchCategory()
    }
  },

  methods: {
    ...mapActions(['getCategory', 'createCategory', 'updateCategory']),

    isUpdateForm () {
      return this.id !== undefined
    },

    handleRequestMethod () {
      if (this.isUpdateForm()) { return this.updateCategory(this.payload()) }

      return this.createCategory(this.payload())
    },

    successMessage () {
      if (this.isUpdateForm()) { return 'Categoria alterada com sucesso.' }

      return 'Categoria cadastrada com sucesso.'
    },

    async fetchCategory () {
      try {
        const { data } = await this.getCategory(this.id)
        this.category = data.category
      } catch (error) {
        this.$toast.error('Houve algo de errado, por favor tente novamente mais tarde.')
      }
    },

    async handleSubmit () {
      const isValid = await this.$v.$validate()
      if (!isValid) { return }

      try {
        await this.handleRequestMethod()
        this.$toast.success(this.successMessage())
        this.$router.push({ name: 'category-list' })
      } catch (error) {
        this.handleResponseError(error)
      }
    },

    payload () {
      return {
        id: this.id,
        title: this.category.title
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/constants.scss';
  .category-upsert {
    &__form {
      padding: $form-page-padding;
    }
  }
</style>
