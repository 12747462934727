<template>
  <modal-dialog
    title="Projeto"
    :show="show"
    @modal-close="handleModalClose"
    class="project-status-modal"
  >
    <template v-slot:content>
      <div>
        <answered-field
          label="Código"
          data-test="project-code"
          :value="project.code"
        />

        <answered-field
          label="Cliente"
          data-test="project-client"
          :value="project.clientName"
        />

        <answered-field
          label="Data de encerramento"
          data-test="project-finish-date"
          :value="project.formattedFinishDate"
        />

        <form
          @submit.prevent="handleChangeStatus"
          v-if="shouldRenderStatusButton"
          data-test="change-status-project-button"
        >
          <form-button
            :variant="buttonVariant"
            class="w-100"
          >
            Mudar status para {{ nextStatusName }}
          </form-button>
        </form>
      </div>
    </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/ModalDialog.vue'
import AnsweredField from '@/components/form/AnsweredField.vue'
import FormButton from '@/components/form/FormButton.vue'
import { mapActions } from 'vuex'
import formErrorHandler from '@/mixins/views/form-error-handler'

export default {
  name: 'ProjectStatusModal',

  components: {
    ModalDialog,
    AnsweredField,
    FormButton
  },

  mixins: [formErrorHandler],

  props: {
    project: {
      type: Object,
      required: true
    },

    show: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    nextStatusName () {
      if (this.project.status === 'pending') { return 'em andamento' }
      if (this.project.status === 'started') { return 'finalizado' }

      return ''
    },

    nextStatus () {
      if (this.project.status === 'pending') { return 'started' }
      if (this.project.status === 'started') { return 'finished' }

      return ''
    },

    buttonVariant () {
      if (this.project.status === 'pending') { return 'outline-secondary' }
      if (this.project.status === 'started') { return 'outline-primary' }

      return ''
    },

    shouldRenderStatusButton () {
      if (this.project.status === 'pending' || this.project.status === 'started') { return true }

      return false
    }
  },

  methods: {
    ...mapActions(['updateProject']),

    handleModalClose () {
      this.$emit('close-project-status-modal')
    },

    handleChangeStatus () {
      if (confirm('Tem certeza ?')) {
        this.updateProject({ id: this.project.id, status: this.nextStatus })
          .then(_response => {
            this.$toast.success('Novo status salvo com sucesso.')
            this.$emit('project-status-changed')
          })
          .catch(error => this.handleResponseError(error))
      }
    }
  }
}
</script>
