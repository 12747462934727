import axios from 'axios'
import router from '../router'

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 5000
})

api.interceptors.request.use(config => {
  const token = localStorage.getItem('authToken')
  if (token) { config.headers.Authorization = `Bearer ${token}` }

  return config
})

// NOTE: this interceptor catches all responses, but handle only 401 to redirect user to new session
//   view when this happen.
api.interceptors.response.use(
  function (response) {
    return response
  }, function (error) {
    if (error.response.status === 401) {
      // handle authentication error on new sessioon view
      if (router.history.current.name === 'new-session') { throw error }

      localStorage.clear()
      router.push({ name: 'new-session' })
      return null
    }

    return Promise.reject(error)
  }
)

export default api
