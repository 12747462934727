import api from '@/lib/api'

export default {
  createProduct (_, product) {
    return api.post('/products', { product })
  },

  getProduct (_, productId) {
    return api.get(`/products/${productId}`)
  },

  updateProduct (_, product) {
    return api.put(`/products/${product.id}`, { product })
  },

  removeProduct (_, productId) {
    return api.delete(`/products/${productId}`)
  }
}
