<template>
  <main class="payment-list-container">
    <div class="payment-list">
      <div class="payment-list__card-list">
        <div v-if="showCreatePaymentButton" @click="togglePaymentUpsert" class="payment-list__new-payment-link">
          <a><i class="bi-cash-coin"/><br /> Adicionar novo pagamento</a>
        </div>
        <payment-card
          v-for="(payment, index) in payments"
          :key="payment.id"
          :payment="{code: index, ...payment}"
          class="payment-list__card"
          @payment-modal-open="showPayment"
        />
      </div>

      <payment-upsert
        v-if="paymentUpsertModal.isVisible"
        :show="paymentUpsertModal.isVisible"
        :id="paymentUpsertModal.paymentId"
        :budgetId="budgetId"
        @payment-upsert-close="togglePaymentUpsert"
      />
    </div>
  </main>
</template>

<script>
import PaymentCard from '@/components/PaymentCard'
import PaymentUpsert from '@/views/private/payments/PaymentUpsert'

export default {
  data () {
    return {
      paymentUpsertModal: {
        paymentId: undefined,
        isVisible: false
      }
    }
  },

  components: {
    PaymentCard,
    PaymentUpsert
  },

  props: {
    payments: {
      type: Array,
      required: true
    },

    budgetId: {
      type: String,
      default: undefined
    },

    showCreatePaymentButton: {
      type: Boolean,
      default: false
    },

    activePaymentId: {
      type: String,
      default: undefined
    }
  },

  mounted () {
    if (this.activePaymentId) {
      this.paymentUpsertModal = {
        paymentId: this.activePaymentId,
        isVisible: true
      }
    }
  },

  methods: {
    togglePaymentUpsert () {
      this.paymentUpsertModal.paymentId = undefined
      this.paymentUpsertModal.isVisible = !this.paymentUpsertModal.isVisible
    },

    showPayment ({ paymentId }) {
      this.togglePaymentUpsert()
      this.paymentUpsertModal.paymentId = paymentId
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/constants.scss';
@import '@/styles/card.scss';

.payment-list {
  &__card-list {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: repeat(auto-fill, minmax($card-list-column-min, $card-list-column-max));
      grid-gap: $card-list-grid-gap;
      margin: $card-list-margin;
      justify-items: center;
  }

  &__card {
    width: 100%;
    align-self: center;
    justify-self: center;
  }

  &__new-payment-link {
    @extend .card;
    background-color: transparent;

    a {
      font-size: 1.6em;
      color: $gray-lighter-color !important;
      text-align: center;
      text-decoration: none;
      padding: 1em;
      cursor: pointer;

      i {
        font-size: 2em;
      }
    }

    a:hover {
      color: $secondary-color !important;
    }
  }
}
</style>
