exports.state = {
  budget: {
    id: '',
    status: '',
    start_date: '',
    finish_date: '',
    estimated_workload: 0,
    workload: 0,
    estimated_amount: 0,
    amount: 0,
    duration: '',
    project: {},
    payments: [],
    products: []
  }
}
