export default {
  getFilterBy (state) {
    return state.reports.filterBy
  },

  getYearReference (state) {
    return state.reports.yearReference
  },

  getMonthReference (state) {
    return state.reports.monthReference
  }
}
