<template>
  <main>
    <user-navbar />

    <div class="category-show">
      <header class="d-flex justify-content-between mb-3">
        <h2 data-test="category-title">{{ category.title }}</h2>

        <div class="action-buttons ">
          <router-link
            :to="{ name: 'category-edit', params: { id: category.id } }"
            class="btn edit-link mx-2">
              <i class="bi-pencil-square" />
             Editar
          </router-link>

        <button
          @click="handleRemoval"
          class="btn remove-button">
          <i class="bi-trash" />
          Remover
        </button>
        </div>
      </header>

      <div class="category-show__subcategories-list">
        <h4 class="mb-2">Subcategorias</h4>

        <div class="subcategory-list__card-list ">
          <div class="subcategory-list__new-category-link">
            <router-link
              :to="{ name: 'subcategory-new', params: { categoryId: category.id } }"
            >
              <i class="bi-plus" />
              <br />Adicionar nova subcategoria
            </router-link>
          </div>

          <category-card
            v-for="category in subcategoriesList"
            :key="category.id"
            :category="category"
            class="subcategory-list__card"
            actionLink="subcategory-upsert"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import UserNavbar from '@/components/UserNavbar'
import CategoryCard from '@/components/CategoryCard'
import { mapActions } from 'vuex'

export default {
  components: { UserNavbar, CategoryCard },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      category: {
        id: '',
        title: '',
        subcategories: []
      }
    }
  },

  mounted () {
    this.fetchCategory()
  },

  computed: {
    subcategoriesList () {
      return this.category.subcategories.map(s => s.subcategory)
    }
  },

  methods: {
    ...mapActions(['getCategory', 'removeCategory']),
    async fetchCategory () {
      try {
        const { data } = await this.getCategory(this.id)
        this.category = data.category
      } catch (error) {
        this.$toast.error('Houve algo de errado, por favor tente novamente mais tarde.')
      }
    },
    async handleRemoval () {
      if (confirm('Tem certeza?')) {
        try {
          await this.removeCategory(this.id)
          this.$toast.success('Categoria removida com sucesso.')
          this.$router.push({ name: 'category-list' })
        } catch (error) {
          this.$toast.error('Houve algo de errado, por favor tente novamente mais tarde.')
        }
      }
    }

  }
}
</script>

<style lang="scss" scoped>
@import 'bootstrap/dist/css/bootstrap';
@import '@/styles/constants.scss';
@import '@/styles/card.scss';
  .category-show {
    padding: $form-page-padding;

    .remove-button {
      color: $danger-color !important;
      background-color: transparent !important;
      border: 1px solid $danger-dark-color !important;
      &:hover {
        border: 1px solid $danger-color !important;
      }
      &:disabled {
        color: rgba($color: white, $alpha: 0.6);
      }
     }

    .edit-link  {
      color: $secondary-color !important;
      background-color: transparent !important;
      border: 1px solid $secondary-dark-color !important;
      &:hover {
        border: 1px solid $secondary-color !important;
      }
      &:disabled {
        color: rgba($color: white, $alpha: 0.6);
      }
    }

    &__edit-link:hover {
      color: white !important;
      background-color: $secondary-dark-color;
    }

     .subcategory-list {
        &__card-list {
          display: grid;
          grid-auto-rows: 1fr;
          grid-template-columns: repeat(auto-fill, minmax($card-list-column-min, $card-list-column-max));
          grid-gap: $card-list-grid-gap;
          margin: $card-list-margin;
          justify-items: center;
        }

        &__card {
          width: 100%;
          align-self: center;
          justify-self: center;
        }

      &__new-category-link {
          @extend .card;
          background-color: transparent;
          width: 100%;
          align-self: center;
          justify-self: center;

          a {
            color: $gray-lighter-color !important;
            text-align: center;
            text-decoration: none;
            cursor: pointer;

            i {
              font-size: 2em;
            }
          }

          a:hover {
            color: $secondary-color !important;
          }
        }
    }
  }
</style>
