<template>
  <span class="rounded-badge" :class="cssClasses">
    <slot />
  </span>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      require: true
    }
  },

  computed: {
    cssClasses () {
      return `rounded-badge__${this.variant}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/constants.scss';

  .rounded-badge {
    border-radius: 50rem;
    padding: 3px 10px 3px 10px;
    color: white;
    font-weight: 400;

    &__primary {
      background-color: $primary-color;
    }

    &__secondary {
      background-color: $secondary-light-color;
    }

    &__danger {
      background-color: $danger-color;
    }
  }
</style>
