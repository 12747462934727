<template>
  <div class="product-card" data-test="project-card">
    <span class="product-card__category">{{ product.category }}</span>

    <rounded-badge
      :variant="productStatusColor"
      class="product-card__status"
      data-test="product-status"
    >
      {{ productStatusText }}
    </rounded-badge>

    <div class="product-card__start-date">
      <span class="label">Data de início:</span>

      <span>{{product.estimated_start_date }}</span>
      </div>
    <div class="product-card__hour-price">
      <span class="label">Preço/h:</span>
      <span>R$ {{ formatMoney(product.hour_price )}}</span>
    </div>

    <div @click="showProduct" class="product-card__link">
      ver mais <i class="bi-chevron-right"></i>
    </div>
  </div>
</template>

<script>
import RoundedBadge from '@/components/RoundedBadge'

export default {
  components: {
    RoundedBadge
  },

  props: {
    product: {
      type: Object,
      required: true
    }
  },

  computed: {
    productStatusColor () {
      const status = {
        pending: 'danger',
        started: 'secondary',
        finished: 'primary'
      }

      return status[this.product.status]
    },

    productStatusText () {
      const status = {
        pending: 'Não iniciado',
        started: 'Iniciado',
        finished: 'Concluído'
      }

      return status[this.product.status]
    }
  },

  methods: {
    showProduct () {
      this.$emit('product-modal-open', { productId: this.product.id })
    },

    formattedHourPrice () {
      return this.product.hour_price?.toFixed(2)
    },

    formatMoney (field) {
      return field?.toLocaleString('pt-BR', { minimumFractionDigits: 2 })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/constants.scss';
@import '@/styles/card.scss';

  .product-card {
    @extend .card;

    &__category {
      font-weight: bolder;
      text-align: center;
      width: 100%;
    }

    &__status {
      font-size: $card-status-fs;
    }

    &__start-date,
    &__hour-price {
      display: flex;
      flex-direction: column;
      align-items: center;

      .label {
        font-weight: 300;
      }
    }

    &__link {
      color: $secondary-color;
      text-decoration: none;
      cursor: pointer;
    }

    &__link:hover {
      color: $secondary-dark-color;
    }
  }
</style>
