<template>
  <main>
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h3>Serviços</h3>
        <tool-tip icon="bi-info-circle" text="Quantidade de serviços agrupados pelo status e filtrados pela data de início do serviço" />
      </div>
      <div class="card-body">
        <canvas id="productsChart" />
      </div>
    </div>
  </main>
</template>

<script>
import { Chart, registerables } from 'chart.js/auto'
import { mapActions, mapGetters } from 'vuex'
import ToolTip from '@/components/Tooltip'
import loadErrorHandler from '@/mixins/views/load-error-handler'

const PENDING = { color: '#FC7676', label: 'Pendente', attribute: 'pending' }
const STARTED = { color: '#35BCC2', label: 'Iniciado', attribute: 'started' }
const FINISHED = { color: '#ADBF00', label: 'Finalizado', attribute: 'finished' }
const CHART_TYPE = 'bar'

export default {
  name: 'ProductsByStatus',

  components: {
    ToolTip
  },

  mixins: [loadErrorHandler],

  data () {
    return {
      labels: [],
      pending: [],
      started: [],
      finished: [],
      chart: null
    }
  },

  mounted () {
    Chart.register(...registerables)
    this.handleFilterChange()
  },

  computed: {
    chartOptions () {
      return {
        scales: {
          x: { stacked: true },
          y: { beginAtZero: true, stacked: true }
        }
      }
    },

    chartData () {
      return {
        labels: this.labels,
        datasets: [
          this.datasetBuilder(PENDING),
          this.datasetBuilder(STARTED),
          this.datasetBuilder(FINISHED)
        ]
      }
    },

    filterBy () {
      return this.getFilterBy()
    },

    monthReference () {
      return this.getMonthReference()
    },

    yearReference () {
      return this.getYearReference()
    },

    canvasElement () {
      return document.getElementById('productsChart')
    },

    changedFilter () {
      return {
        monthReference: this.monthReference,
        yearReference: this.yearReference
      }
    }
  },

  methods: {
    ...mapActions(['getProductsByStatusReport']),
    ...mapGetters(['getFilterBy', 'getYearReference', 'getMonthReference']),
    datasetBuilder (dataClass) {
      return {
        label: dataClass.label,
        data: this[dataClass.attribute],
        borderWidth: 1,
        backgroundColor: dataClass.color
      }
    },

    handleReturnedData (data) {
      this.labels = data.group_labels
      this.labels.forEach(dateReference => {
        this.pending.push(data.report_data[dateReference][PENDING.label])
        this.started.push(data.report_data[dateReference][STARTED.label])
        this.finished.push(data.report_data[dateReference][FINISHED.label])
      })
    },

    handleFilterChange () {
      this.getProductsByStatusReport({
        filterBy: this.filterBy,
        yearReference: this.yearReference,
        monthReference: this.monthReference
      })
        .then(response => {
          this.handleReturnedData(response.data)

          this.chart?.destroy()

          /* eslint-disable no-new */
          this.chart = new Chart(this.canvasElement, {
            type: CHART_TYPE,
            data: this.chartData,
            options: this.chartOptions
          })
          /* eslint-enable no-new */
        }).catch(error => this.handleLoadError(error))
    }
  },

  watch: {
    changedFilter (_oldValue, _newValue) {
      this.handleFilterChange()
    }
  }
}
</script>
