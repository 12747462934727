import { render, staticRenderFns } from "./UserAlerts.vue?vue&type=template&id=eeda5730&scoped=true&"
import script from "./UserAlerts.vue?vue&type=script&lang=js&"
export * from "./UserAlerts.vue?vue&type=script&lang=js&"
import style0 from "./UserAlerts.vue?vue&type=style&index=0&id=eeda5730&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eeda5730",
  null
  
)

export default component.exports