<template>
  <div>
    <project-status-modal
      :show="showProjectModal"
      @close-project-status-modal="handleCloseProjectModal"
      :project="selectedProject"
      @project-status-changed="handleProjectStatusChanged"
      data-test="project-modal"
    />

    <div class="kanban-board d-flex col-10 offset-1">
      <div
        class="col-3 offset-1"
        data-test="pending-column"
      >
        <div class="d-flex justify-content-between mb-4">
          <h3 class="mb-0">Não iniciado</h3>

          <span
            class="kanban-board__counter"
            data-test="pending-counter"
          >
            {{ pending.length }}
          </span>
        </div>

        <kanban-card
          v-for="project in pending"
          :key="project.id"
          :project="project"
          class="mb-3"
          @change-project-status-modal="handleProjectCardClick(project)"
        />
      </div>

      <div
        class="col-3 offset-1"
        data-test="started-column"
      >
        <div class="d-flex justify-content-between mb-4">
          <h3 class="mb-0">Em andamento</h3>

          <span
            class="kanban-board__counter"
            data-test="started-counter"
          >
            {{ started.length }}
          </span>
        </div>

        <kanban-card
          v-for="project in started"
          :key="project.id"
          :project="project"
          @change-project-status-modal="handleProjectCardClick(project)"
        />
      </div>

      <div
        data-test="finished-column"
        class="col-3 offset-1"
      >
        <div class="d-flex justify-content-between mb-4">
          <h3 class="mb-0">Finalizado</h3>

          <span
            class="kanban-board__counter"
            data-test="finished-counter"
          >
            {{ finished.length }}
          </span>
        </div>

        <kanban-card
          v-for="project in finished"
          :key="project.id"
          :project="project"
          @change-project-status-modal="handleProjectCardClick(project)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import projectBuilder from '@/builders/projectBuilder'
import { mapActions } from 'vuex'
import loadErrorHandler from '@/mixins/views/load-error-handler'
import KanbanCard from '@/components/kanban/KanbanCard.vue'
import ProjectStatusModal from '@/components/kanban/ProjectStatusModal.vue'

export default {
  name: 'KanbanBoard',

  components: {
    KanbanCard,
    ProjectStatusModal
  },

  mixins: [loadErrorHandler],

  data () {
    return {
      pending: [],
      started: [],
      finished: [],
      showProjectModal: false,
      selectedProject: projectBuilder({})
    }
  },

  mounted () {
    this.fetchProjects()
  },

  methods: {
    ...mapActions(['projectKanban']),

    buildProjects (rawProjects) {
      return rawProjects.map(({ project }) => projectBuilder(project))
    },

    handleProjectCardClick (project) {
      this.showProjectModal = true
      this.selectedProject = project
    },

    handleCloseProjectModal () {
      this.selectedProject = projectBuilder({})
      this.showProjectModal = false
    },

    handleProjectStatusChanged () {
      this.selectedProject = projectBuilder({})
      this.showProjectModal = false
      this.fetchProjects()
    },

    fetchProjects () {
      this.projectKanban()
        .then(response => {
          this.pending = this.buildProjects(response.data.projects.pending)
          this.started = this.buildProjects(response.data.projects.started)
          this.finished = this.buildProjects(response.data.projects.finished)
        })
        .catch(error => this.handleLoadError(error))
    }
  }
}

</script>

<style lang="scss" scoped>
@import '@/styles/constants.scss';
@import 'bootstrap/dist/css/bootstrap';

  .kanban-board {
    &__counter {
      @extend .text-center, .text-white, .fw-bold;
      background-color: $secondary-light-color;
      border-radius: 5em;
      width: 3em;
      padding: 3px;
    }
 }
</style>
