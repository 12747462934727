<template>
  <modal-dialog :show="show" @modal-close="close" title="Projetos" class="project-upsert">
    <template v-slot:content>
       <div class="project-upsert__form">
        <form @submit.prevent="handleSubmit()">
          <div class="col-6 mb-5">
            <input-field
              v-if="isUpdateForm()"
              label="Código"
              v-model="project.code"
              data-test="project-code"
              :errorMessage="handleError($v)"
            />

            <date-field
              label="Data de início"
              v-model="project.start_date"
              data-test="project-start-date"
              :errorMessage="handleError($v)"
            />

            <date-field
              label="Data de encerramento"
              v-model="project.finish_date"
              data-test="project-finish-date"
              :errorMessage="handleError($v)"
            />

            <select-field
              v-if="project.id !== undefined"
              label="Status"
              v-model="project.status"
              :options="project_status"
              data-test="project-status"
              :errorMessage="handleError($v)"
            />

            <select-field
              label="Cliente"
              :options="clients"
              v-model="project.client.id"
              data-test="project-client"
              :errorMessage="handleError($v)"
            />

            <select-field
              label="Responsável"
              :options="users"
              v-model="project.manager.id"
              data-test="project-manager"
              :errorMessage="handleError($v)"
            />

          </div>

          <div class="d-flex justify-content-end">
            <form-button
              variant="primary"
              data-test="form-button"
              class="px-5"
            >
              Salvar
            </form-button>
          </div>
        </form>
       </div>
    </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/ModalDialog'
import InputField from '@/components/form/InputField'
import DateField from '@/components/form/DateField'
import SelectField from '@/components/form/SelectField'
import FormButton from '@/components/form/FormButton'
import formErrorHandler from '@/mixins/views/form-error-handler'
import { projectStatusOptions } from '@/enums/status'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { validDate } from '@/validators/index'
import { mapActions } from 'vuex'

export default {
  components: { ModalDialog, InputField, DateField, SelectField, FormButton },

  mixins: [formErrorHandler],

  props: {
    show: {
      type: Boolean,
      default: false
    },

    loaded_project: {
      type: Object,
      default: undefined
    }
  },

  data () {
    return {
      project: {
        code: '',
        start_date: '',
        finish_date: '',
        client: {
          id: ''
        },
        manager: {
          id: ''
        }
      },
      status: '',
      clients: [],
      users: [],
      project_status: projectStatusOptions()
    }
  },

  setup: () => ({ $v: useVuelidate() }),

  validations () {
    if (this.isUpdateForm()) {
      return {
        project: {
          code: { required },
          start_date: { validDate },
          finish_date: { validDate },
          client: {
            id: { required }
          },
          manager: {
            id: { required }
          }
        }
      }
    }

    return {
      project: {
        start_date: { validDate },
        finish_date: { validDate },
        client: {
          id: { required }
        },
        manager: {
          id: { required }
        }
      }
    }
  },

  async beforeMount () {
    await this.fetchClients()
    await this.fetchUsers()
    if (this.loaded_project !== undefined) {
      this.project = this.loaded_project
    }

    this.definedLoggedUserAsManager()
  },

  methods: {
    ...mapActions(['allUsers', 'allClients', 'updateProject', 'createProject']),

    isUpdateForm () {
      return this.project.id !== undefined
    },

    handleRequestMethod () {
      if (this.isUpdateForm()) { return this.updateProject(this.payload()) }

      return this.createProject(this.payload())
    },

    successMessage () {
      if (this.isUpdateForm()) { return 'Projeto editado com sucesso.' }

      return 'Projeto cadastrado com sucesso.'
    },

    async handleSubmit () {
      const isValid = await this.$v.$validate()

      if (!isValid) { return }

      try {
        const { data } = await this.handleRequestMethod()
        this.$toast.success(this.successMessage())
        this.close()
        const projectId = data.project.id

        if (this.isUpdateForm()) {
          this.$emit('project-upsert-reload')
          return
        }

        this.$router.push({ name: 'project-show', params: { id: projectId } })
      } catch (error) {
        this.handleResponseError(error)
      }
    },

    payload () {
      return {
        id: this.project.id,
        code: this.project.code,
        start_date: this.project.start_date,
        finish_date: this.project.finish_date,
        client_id: this.project.client.id,
        manager_id: this.project.manager.id,
        status: this.project.status
      }
    },

    close () {
      this.$emit('project-upsert-close')
    },

    async fetchClients () {
      const blankSpace = [{ value: undefined, label: '' }]
      try {
        const response = await this.allClients()
        const { clients } = response.data
        this.clients = [blankSpace, ...clients.map(({ client }) =>
          ({
            value: client.id,
            label: client.name
          })
        )]
      } catch (error) {
        this.$toast.error('Houve algo de errado, por favor tente novamente mais tarde.')
      }
    },

    async fetchUsers () {
      const blankSpace = [{ value: undefined, label: '' }]
      try {
        const { data } = await this.allUsers()
        this.users = [blankSpace, ...data.users.map(({ user }) =>
          ({
            value: user.id,
            label: user.name
          })
        )]
      } catch (error) {
        this.$toast.error('Houve algo de errado, por favor tente novamente mais tarde.')
      }
    },

    definedLoggedUserAsManager () {
      if (this.isUpdateForm()) return

      const loggedUserId = localStorage.getItem('userId')
      if (!loggedUserId) return

      this.project.manager.id = loggedUserId
    }
  }
}
</script>

<style>

</style>
