<template>
  <main>
    <user-navbar />

    <div class="project-show">
      <div class="d-flex justify-content-between mb-3">
        <h2 data-test="project-code">{{ project.code }}</h2>

        <div class="action-buttons">
          <button class="action-button edit mx-2" @click="toggleProjectUpsert">
            <i class="bi-pencil-square" />
          </button>
          <button class="action-button remove" @click="handleRemoval">
            <i class="bi-trash" />
          </button>
        </div>
      </div>

      <payments-overview :projectId="this.id" :listPayments="false" class="payments-overview"/>

      <div class="d-flex justify-content-between w-100">
        <div class="project-show-overview">
          <h5 class="project-show-overview__title">Resumo</h5>
          <project-overview :project="project" />
        </div>

        <div class="budgets-container px-4">
          <div class="d-flex justify-content-between mb-3">
            <h5 class="project-show-overview__title">Orçamentos</h5>
            <router-link :to="{ name: 'budget-new', params: newProjectProps() }">
              <form-button variant="primary">Novo orçamento</form-button>
            </router-link>
          </div>

          <table-items
            :fields='["Data de início", "Status"]'
            :items="budgets"
            :hasRowNumber="true"
            data-test="project-budgets"
            >
            <slot slot-scope="{ row }">
              <td data-test="project-budget">{{row.start_date}} </td>
              <td>
                <rounded-badge :variant="budgetStatusBadgeColor(row.status)">{{ budgetStatusBadgeText(row.status) }}</rounded-badge>
              </td>
              <td>
                <router-link :to="{ name: 'budget-edit', params: { id: row.id } }" class="item__link">
                  ver mais <i class="bi-chevron-right" />
                </router-link>
              </td>
            </slot>
          </table-items>
        </div>
      </div>

      <div v-if="products.length > 0" class="product-list mt-2" data-test="project-product-list">
        <h3>Serviços</h3>
        <product-list :products="products"/>
      </div>

      <div v-if="payments.length > 0" class="product-list mt-2" data-test="project-payment-list">
        <h3>Pagamentos</h3>
        <payment-list :payments="payments" />
      </div>
    </div>

    <project-upsert v-if="projectUpsertModal.isVisible" :show="projectUpsertModal.isVisible" :loaded_project="project" @project-upsert-close="toggleProjectUpsert" @project-upsert-reload="fetchProject" />
  </main>
</template>

<script>
import UserNavbar from '@/components/UserNavbar'
import ProjectOverview from '@/components/ProjectOverview'
import TableItems from '@/components/TableItems'
import FormButton from '@/components/form/FormButton'
import RoundedBadge from '@/components/RoundedBadge'
import ProjectUpsert from '@/views/private/projects/ProjectUpsert'
import ProductList from '@/views/private/products/ProductList'
import PaymentList from '@/views/private/payments/PaymentList'
import PaymentsOverview from '@/components/financial/PaymentsOverview'
import { mapActions } from 'vuex'

export default {
  components: {
    UserNavbar,
    ProjectOverview,
    TableItems,
    FormButton,
    RoundedBadge,
    ProjectUpsert,
    ProductList,
    PaymentList,
    PaymentsOverview
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      project: {
        id: '',
        code: '',
        status: '',
        start_date: '',
        finish_date: '',
        estimated_workload: 0,
        workload: 0,
        estimated_amount: 0,
        amount: 0,
        client: '',
        budgets: [],
        products: [],
        payments: []
      },
      projectUpsertModal: {
        isVisible: false
      }
    }
  },

  computed: {
    budgets () {
      return this.project.budgets.map(b => b.budget)
    },
    products () {
      return this.project.products.map(p => p.product)
    },
    payments () {
      return this.project.payments?.map(p => p.payment)
    }
  },

  async mounted () {
    await this.fetchProject()
  },

  methods: {
    ...mapActions(['getProject', 'removeProject']),

    budgetStatusBadgeColor (status) {
      const colors = {
        rejected: 'danger',
        negotiation: 'secondary',
        approved: 'primary'
      }

      return colors[status]
    },

    budgetStatusBadgeText (status) {
      const texts = {
        rejected: 'Reprovado',
        negotiation: 'Em negociação',
        approved: 'Aprovado'
      }

      return texts[status]
    },

    async fetchProject () {
      try {
        const { data } = await this.getProject(this.id)
        this.project = data.project
      } catch (error) {
        this.$toast.error('Houve algo de errado, por favor tente novamente mais tarde.')
      }
    },

    async handleRemoval () {
      if (confirm('Tem certeza?')) {
        try {
          await this.removeProject(this.id)
          this.$toast.success('Projeto removido com sucesso.')
          this.$router.push({ name: 'project-list' })
        } catch (error) {
          this.$toast.error('Houve algo de errado, por favor tente novamente mais tarde.')
        }
      }
    },

    toggleProjectUpsert () {
      this.projectUpsertModal.isVisible = !this.projectUpsertModal.isVisible
    },

    newProjectProps () {
      return {
        project_id: this.id,
        projectStartDate: this.project.start_date,
        projectFinishDate: this.project.finish_date
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@import 'bootstrap/dist/css/bootstrap';
@import '@/styles/constants.scss';

  .project-show {
    margin-top: 1.2em;
    padding: 2em;

    .action-button {
      @extend .btn;

     &.remove {
        color: $danger-color !important;
        background-color: transparent !important;
        border: 1px solid $danger-dark-color !important;
        &:hover {
          border: 1px solid $danger-color !important;
        }
        &:disabled {
          color: rgba($color: white, $alpha: 0.6);
        }
     }

     &.edit {
        color: $secondary-color !important;
        background-color: transparent !important;
        border: 1px solid $secondary-dark-color !important;
        &:hover {
          border: 1px solid $secondary-color !important;
        }
        &:disabled {
          color: rgba($color: white, $alpha: 0.6);
        }
     }
    }

    .budgets-container {
      flex-grow: .90;
      display: flex;
      flex-flow: column;
      align-content: center;
    }
  }
</style>
