<template>
  <main class="client-list">
    <user-navbar />

    <div class="client-list__header">
      <h3>Clientes</h3>

      <div class="client-list__search-options">
        <input-field
          v-model="filters.name"
          variant="rounded"
          placeholder="Busque por clientes"
          @enter="searchClients"
        />
        <form-button @click="searchClients" variant="primary" class="mb-3">
          <i class="bi-search"/>
        </form-button>
      </div>
    </div>

    <div class="client-list__card-list">
      <div class="client-list__new-client-link">
        <router-link :to="{ name: 'client-new' }">
          <i class="bi-person-plus" />
          <br />
          Adicionar novo cliente
        </router-link>
      </div>

      <client-card v-for="client in clientList" :key="client.id" :client="client" class="client-list__card" />
    </div>

    <list-pagination
      :totalPages="totalPages"
      :currentPage="currentPage"
      @changed="handleChange"
    />
  </main>
</template>

<script>

import UserNavbar from '@/components/UserNavbar'
import ClientCard from '@/components/ClientCard'
import ListPagination from '@/components/ListPagination'
import InputField from '@/components/form/InputField'
import FormButton from '@/components/form/FormButton'
import { mapActions } from 'vuex'

export default {
  components: { UserNavbar, ClientCard, ListPagination, InputField, FormButton },

  data () {
    return {
      clientList: [],
      currentPage: 1,
      totalPages: 0,
      filters: {
        name: null
      }
    }
  },

  mounted () {
    this.fetchClients()
  },

  methods: {
    ...mapActions(['listClients']),

    handleChange (newIndex) {
      this.currentPage = newIndex
    },

    fetchClients () {
      const params = { page: this.currentPage, name: this.filters.name }
      this.listClients(params).then(({ data }) => {
        this.clientList = data.clients.map(c => c.client)
        this.currentPage = parseInt(data.current_page)
        this.totalPages = parseInt(data.total_pages)
      })
    },

    searchClients () {
      this.currentPage = 1
      this.fetchClients()
    }
  },

  watch: {
    currentPage () {
      this.fetchClients()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/constants.scss';

  .client-list {
    &__header {
      padding: $form-page-padding;
      padding-bottom: 0;
      display: flex;
      justify-content: space-between;
    }

    &__search-options {
      display: flex;
      justify-content: space-between;
      align-items: center;

      column-gap: 5px;
    }

    &__card-list {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: repeat(auto-fill, minmax($card-list-column-min, $card-list-column-max));
      grid-gap: $card-list-grid-gap;
      margin: $card-list-margin;
      justify-items: center;
    }

    &__card {
      width: 100%;
      align-self: center;
      justify-self: center;
    }

    &__new-client-link {
      display: flex;
      align-content: center;
      justify-content: center;

      a {
        font-size: 1.75em;
        color: $gray-lighter-color;
        text-align: center;
        text-decoration: none;
        padding: 1em;

        i {
          font-size: 2em;
        }
      }

      a:hover {
        color: $secondary-color;
      }
    }
  }
</style>
