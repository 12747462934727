import { render, staticRenderFns } from "./CategoryShow.vue?vue&type=template&id=ef68a2b4&scoped=true&"
import script from "./CategoryShow.vue?vue&type=script&lang=js&"
export * from "./CategoryShow.vue?vue&type=script&lang=js&"
import style0 from "./CategoryShow.vue?vue&type=style&index=0&id=ef68a2b4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef68a2b4",
  null
  
)

export default component.exports