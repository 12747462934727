import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import NewSession from '@/views/public/NewSession'
import UserList from '@/views/private/users/UserList'

import UserUpsert from '@/views/private/users/UserUpsert'
import ClientList from '@/views/private/clients/ClientList'
import ClientUpsert from '@/views/private/clients/ClientUpsert'
import UserShow from '@/views/private/users/UserShow'
import ClientShow from '@/views/private/clients/ClientShow'
import ProjectList from '@/views/private/projects/ProjectList'
import ProjectShow from '@/views/private/projects/ProjectShow'
import BudgetUpsert from '@/views/private/budgets/BudgetUpsert'
import CategoryList from '@/views/private/categories/CategoryList'
import CategoryUpsert from '@/views/private/categories/CategoryUpsert'
import CategoryShow from '@/views/private/categories/CategoryShow'
import SubcategoryUpsert from '@/views/private/subcategories/SubcategoryUpsert'
import FinancialHome from '@/views/private/financial/FinancialHome'
import ReportsView from '@/views/private/reports/ReportsView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { public: false }
  },
  {
    path: '/new-session',
    name: 'new-session',
    component: NewSession,
    meta: { public: true }
  },
  {
    path: '/users',
    name: 'user-list',
    component: UserList,
    meta: { public: false, currentTab: 'users' }
  },
  {
    path: '/users/new',
    component: UserUpsert,
    name: 'user-new',
    meta: { public: false, currentTab: 'users' }
  },
  {
    path: '/users/:id',
    component: UserShow,
    name: 'user-show',
    props: true,
    meta: { public: false, currentTab: 'users' }
  },
  {
    path: '/users/:id/edit',
    component: UserUpsert,
    name: 'user-edit',
    props: true,
    meta: { public: false, currentTab: 'users' }
  },
  {
    path: '/clients',
    name: 'client-list',
    component: ClientList,
    meta: { public: false, currentTab: 'clients' }
  },
  {
    path: '/clients/new',
    name: 'client-new',
    component: ClientUpsert,
    meta: { public: false, currentTab: 'clients' }
  },
  {
    path: '/clients/:id',
    name: 'client-show',
    component: ClientShow,
    props: true,
    meta: { public: false, currentTab: 'clients' }
  },
  {
    path: '/clients/:id/edit',
    component: ClientUpsert,
    name: 'client-edit',
    props: true,
    meta: { public: false, currentTab: 'clients' }
  },
  {
    path: '/projects',
    component: ProjectList,
    name: 'project-list',
    meta: { public: false, currentTab: 'projects' }
  },
  {
    path: '/projects/:id',
    component: ProjectShow,
    name: 'project-show',
    props: true,
    meta: { public: false, currentTab: 'projects' }
  },
  {
    path: '/projects/:project_id/budgets',
    component: BudgetUpsert,
    name: 'budget-new',
    props: true,
    meta: { public: false, currentTab: 'projects' }
  },
  {
    path: '/budgets/:id',
    component: BudgetUpsert,
    name: 'budget-edit',
    props: true,
    meta: { public: false, currentTab: 'projects' }
  },
  {
    path: '/categories/',
    component: CategoryList,
    name: 'category-list',
    props: false,
    meta: { public: false, currentTab: 'categories' }
  },
  {
    path: '/categories/new',
    component: CategoryUpsert,
    name: 'category-new',
    props: false,
    meta: { public: false, currentTab: 'categories' }
  },
  {
    path: '/categories/:id/edit',
    component: CategoryUpsert,
    name: 'category-edit',
    props: true,
    meta: { public: false, currentTab: 'categories' }
  },
  {
    path: '/categories/:id',
    component: CategoryShow,
    name: 'category-show',
    props: true,
    meta: { public: false, currentTab: 'categories' }
  },
  {
    path: '/subcategories/:id',
    component: SubcategoryUpsert,
    name: 'subcategory-upsert',
    props: true,
    meta: { public: false, currentTab: 'categories' }
  },
  {
    path: '/subcategories',
    component: SubcategoryUpsert,
    name: 'subcategory-new',
    props: true,
    meta: { public: false, currentTab: 'categories' }
  },
  {
    path: '/financial',
    component: FinancialHome,
    name: 'financial-home',
    props: true,
    meta: { public: false, currentTab: 'financial' }
  },
  {
    path: '/reports',
    component: ReportsView,
    name: 'reports',
    meta: { public: false, currentTab: 'reports' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((routeTo, routeFrom, next) => {
  if (!routeTo.meta.public && localStorage.getItem('authToken') === null) {
    return next({ name: 'new-session' })
  }

  next()
})

export default router
