const isValidDate = (date) => {
  if (date === '' || date === null) { return true }

  const hasValidFormat = /^\d\d\/\d\d\/\d\d\d\d$/.test(date)
  if (!hasValidFormat) return false

  let [dd, mm, yyyy] = date.split('/').map(n => parseInt(n, 10))
  mm = mm - 1
  const parsedDate = new Date(yyyy, mm, dd)
  return parsedDate.getMonth() === mm && parsedDate.getDate() === dd && parsedDate.getFullYear() === yyyy
}

export {
  isValidDate
}
