const paymentMethodsOptions = () => {
  return [
    {
      value: 'credit',
      label: 'Cartão de crédito'
    },
    {
      value: 'debit',
      label: 'Cartão de débito'
    },
    {
      value: 'cash',
      label: 'Dinheiro'
    },
    {
      value: 'paypal',
      label: 'PayPal'
    },
    {
      value: 'pix',
      label: 'Pix'
    },
    {
      value: 'bank_transfer',
      label: 'Transferência bancária'
    }
  ]
}

export {
  paymentMethodsOptions
}
