import api from '@/lib/api'

export default {
  createClient (_, client) {
    return api.post('/clients', { client })
  },

  listClients (_, params) {
    return api.get('/clients', { params: { ...params } })
  },

  allClients (_) {
    return api.get('/clients?all=true')
  },

  getClient (_, clientId) {
    return api.get(`/clients/${clientId}`)
  },

  removeClient (_, clientId) {
    return api.delete(`/clients/${clientId}`)
  },

  updateClient (_, client) {
    return api.put(`/clients/${client.id}`, { client })
  }
}
