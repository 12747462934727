const monthsOptions = () => {
  return [
    {
      value: 1,
      label: 'Janeiro'
    },
    {
      value: 2,
      label: 'Fevereiro'
    },
    {
      value: 3,
      label: 'Março'
    },
    {
      value: 4,
      label: 'Abril'
    },
    {
      value: 5,
      label: 'Maio'
    },
    {
      value: 6,
      label: 'Junho'
    },
    {
      value: 7,
      label: 'Julho'
    },
    {
      value: 8,
      label: 'Agosto'
    },
    {
      value: 9,
      label: 'Setembro'
    },
    {
      value: 10,
      label: 'Outubro'
    },
    {
      value: 11,
      label: 'Novembro'
    },
    {
      value: 12,
      label: 'Dezembro'
    }
  ]
}

const yearsOptions = (initialYear = 2022, yearsAhead = 0) => {
  const currentYear = new Date().getFullYear()
  return [...Array(currentYear - initialYear + 1 + yearsAhead).keys()].map(item => {
    return {
      value: item + initialYear,
      label: item + initialYear
    }
  })
}

export {
  monthsOptions,
  yearsOptions
}
