<template>
  <div
    class="kanban-card"
    :class="cardBorder"
    @click="handleClick"
    data-test="kanban-card"
  >
    <div class="kanban-card__session mb-3">
      <span
        class="fw-bold"
        data-test="project-code"
      >
        {{ project.code }}
      </span>

      <span
        class="text-secondary"
        data-test="project-finish-date"
      >
        {{ project.formattedFinishDate }}
      </span>
    </div>

    <div class="kanban-card__session">
      <span
        data-test="project-client-name"
      >
        {{ project.clientName }}
      </span>

      <router-link
        @click.native="$event.stopImmediatePropagation()"
        :to="{ name: 'project-show', params: { id: project.id } }"
        class="kanban-card__link"
      >
        acessar projeto
        <i class="bi-chevron-right" />
      </router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'KanbanCard',

  props: {
    project: {
      type: Object,
      required: true
    }
  },

  computed: {
    cardBorder () {
      if (new Date(this.project.finishDate) < new Date()) { return 'kanban-card__border-danger' }
      if (this.threshouldWarningDate < new Date()) { return 'kanban-card__border-warning' }

      return ''
    },

    threshouldWarningDate () {
      const thresholdDays = 14
      const projectFinishDate = new Date(this.project.finishDate)
      projectFinishDate.setDate(projectFinishDate.getDate() - thresholdDays)
      return projectFinishDate
    }
  },

  methods: {
    handleClick () {
      this.$emit('change-project-status-modal', this.project)
    }
  }
}

</script>

<style lang="scss" scoped>
@import '@/styles/constants.scss';
@import 'bootstrap/dist/css/bootstrap';

.kanban-card {
  @extend .card, .card-body, .mb-2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &__session {
    @extend .d-flex, .justify-content-between;
  }

  &__link {
    color: $secondary-color;
    text-decoration: none;
  }

  &__border-danger {
    border-left: 5px solid $danger-dark-color;
  }

  &__border-warning {
    border-left: 5px solid $primary-dark-color;
  }
}
</style>
