<template>
  <main class="user-list">
    <user-navbar />
    <div class="user-list__card-list">
      <div class="user-list__new-user-link">
        <router-link :to="{ name: 'user-new' }" >
          <i class="bi-person-plus" />
          <br />
          Adicionar novo usuário
        </router-link>
      </div>
      <user-card
        v-for="user in usersList"
        :key="user.id"
        :user="user"
        class="user-list__card"
      />
    </div>
    <list-pagination
      :totalPages="totalPages"
      :currentPage="currentPage"
      @changed="handleChange"
    />
  </main>
</template>

<script>
import UserNavbar from '@/components/UserNavbar.vue'
import UserCard from '@/components/UserCard.vue'
import ListPagination from '@/components/ListPagination.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    ListPagination,
    UserNavbar,
    UserCard
  },

  data () {
    return {
      usersList: [],
      currentPage: 1,
      totalPages: 0
    }
  },

  mounted () {
    this.loadUsers()
  },

  methods: {
    ...mapActions(['listUser']),

    handleChange (newIndex) {
      this.currentPage = newIndex
    },

    loadUsers () {
      this.listUser(this.currentPage)
        .then(response => {
          this.usersList = response.data.users.map(u => u.user)
          this.currentPage = parseInt(response.data.current_page)
          this.totalPages = parseInt(response.data.total_pages)
        })
    }
  },

  watch: {
    currentPage () {
      this.loadUsers()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/constants.scss';

  .user-list {
    &__card-list {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: repeat(auto-fill, minmax($card-list-column-min, $card-list-column-max));
      grid-gap: $card-list-grid-gap;
      margin: $card-list-margin;
      justify-items: center;
    }

    &__card {
      width: 100%;
      align-self: center;
      justify-self: center;
    }

    &__new-user-link {
      display: flex;
      align-content: center;
      justify-content: center;

      a {
        font-size: 1.75em;
        color: $gray-lighter-color;
        text-align: center;
        text-decoration: none;
        padding: 1em;

        i {
          font-size: 2em;
        }
      }

      a:hover {
        color: $secondary-color;
      }
    }
  }
</style>
