import api from '@/lib/api'

export default {
  getSubcategory (_, subcategoryId) {
    return api.get(`/subcategories/${subcategoryId}`)
  },

  updateSubcategory (_, subcategory) {
    return api.put(`/subcategories/${subcategory.id}`, { subcategory })
  },

  createSubcategory (_, subcategory) {
    return api.post('/subcategories', { subcategory })
  },

  removeSubcategory (_, subcategoryId) {
    return api.delete(`/subcategories/${subcategoryId}`)
  }
}
