import api from '@/lib/api'

export default {
  createProject (_, project) {
    return api.post('/projects', { project })
  },

  listProject (_, params) {
    return api.get('/projects', { params: { ...params } })
  },

  getProject (_, projectId) {
    return api.get(`/projects/${projectId}`)
  },

  removeProject (_, projectId) {
    return api.delete(`/projects/${projectId}`)
  },

  updateProject (_, project) {
    return api.put(`/projects/${project.id}`, { project })
  },

  projectKanban () {
    return api.get('/projects/kanban')
  }
}
