<template>
  <div class="payment-card" data-test="payment-card">
    <span class="payment-card__code" data-test="payment-project-code">{{ payment.project.code }}</span>

    <rounded-badge
      :variant="paymentStatusColor"
      class="payment-card__due-date-info"
      data-test="payment-due-date-info"
    >
      {{ paymentStatusText }}
    </rounded-badge>

    <div class="payment-card__date">
      <span class="label">Recebimento:</span>
      <span data-test="payment-date"> {{ payment.date || payment.scheduled_date }} </span>
    </div>

    <div class="payment-card__amount">
      <span class="label">Valor:</span>
      <span data-test="payment-amount">R$ {{  formatMoney(payment.amount) || formatMoney(payment.scheduled_amount) }}</span>
    </div>

    <router-link :to="{ name: 'budget-edit', params: { id: payment.budget_id, paymentId: payment.id } }" class="payment-card__link">
      ver mais <i class="bi-chevron-right" />
    </router-link>
  </div>
</template>

<script>
import RoundedBadge from '@/components/RoundedBadge'

export default {
  components: {
    RoundedBadge
  },

  props: {
    payment: {
      type: Object,
      required: true
    }
  },

  computed: {
    paymentStatusColor () {
      const status = {
        overdue: 'danger',
        receivable: 'secondary'
      }

      return status[this.status]
    },

    paymentStatusText () {
      const status = {
        overdue: `Vencido à ${this.payment.days_since_due_date} dias`,
        receivable: `Vence em ${this.payment.days_until_due_date} dias`
      }

      return status[this.status]
    },

    status () {
      if (this.payment.days_since_due_date > 0) { return 'overdue' }

      return 'receivable'
    }

  },

  methods: {
    formattedAmount () {
      return this.payment.amount?.toFixed(2)
    },

    formattedScheduledAmount () {
      return this.payment.scheduled_amount?.toFixed(2)
    },

    formatMoney (field) {
      return field?.toLocaleString('pt-BR', { minimumFractionDigits: 2 })
    }

  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/constants.scss';
@import '@/styles/card.scss';

.payment-card {
  @extend .card;
  &__code {
    font-weight: bolder;
    text-align: center;
    width: 100%;
  }

  &__due-date-info {
    font-size: $card-status-fs;
  }

  &__date,
  &__amount {
    display: flex;
    flex-direction: column;
    align-items: center;

    .label {
      font-weight: 300;
    }
  }

  &__link {
    color: $secondary-color;
    text-decoration: none;
    cursor: pointer;
  }

  &__link:hover {
    color: $secondary-dark-color;
  }

}
</style>
