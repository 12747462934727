import api from '@/lib/api'

export default {
  listCategories (_) {
    return api.get('/categories')
  },

  createCategory (_, category) {
    return api.post('/categories', { category })
  },

  getCategory (_, categoryId) {
    return api.get(`/categories/${categoryId}`)
  },

  updateCategory (_, category) {
    return api.put(`/categories/${category.id}`, { category })
  },

  removeCategory (_, categoryId) {
    return api.delete(`/categories/${categoryId}`)
  }
}
