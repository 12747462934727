import api from '@/lib/api'

export default {
  async createBudget ({ commit }, budget) {
    const response = await api.post('/budgets', { budget })
    commit('setBudget', response.data.budget)
    return response
  },

  async getBudget ({ commit }, budgetId) {
    const response = await api.get(`/budgets/${budgetId}`)
    commit('setBudget', response.data.budget)

    return response
  },

  async updateBudget ({ commit }, budget) {
    const response = await api.put(`/budgets/${budget.id}`, { budget })
    commit('setBudget', response.data.budget)
    return response
  },

  async removeBudget ({ commit }, budgetId) {
    const response = await api.delete(`/budgets/${budgetId}`)
    commit('resetBudget')

    return response
  },

  async duplicateBudget (_, budgetId) {
    return await api.post(`/budgets/${budgetId}/duplicate`)
  },

  async generateRevision (_, budgetId) {
    return await api.post(`/budgets/${budgetId}/revision`)
  }
}
