<template>
  <div class="overview-card">
    <i class="overview-card__icon" :class="iconClass" data-test="overview-card-icon" />

    <h4 class="overview-card__amount" :class="amountClass" data-test="overview-card-amount">R$ {{ formatAmount(amount) }}</h4>

    <p class="overview-card__description" data-test="overview-card-description">{{ descriptions }}</p>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      require: true
    },
    amount: {
      type: Number,
      require: true
    }
  },

  computed: {
    iconClass () {
      const icons = {
        overdue: 'bi-clipboard-x',
        receivable: 'bi-clipboard-plus',
        received: 'bi-clipboard-check'
      }

      return icons[this.type]
    },

    amountClass () {
      const amount = {
        overdue: 'danger',
        receivable: 'secondary',
        received: 'primary'
      }

      return amount[this.type]
    },

    descriptions () {
      const amount = {
        overdue: 'Soma de pagamentos vencidos',
        receivable: 'Soma de pagamentos à receber',
        received: 'Soma de pagamentos recebidos'
      }

      return amount[this.type]
    }
  },

  methods: {

    formatAmount (amount) {
      return amount.toLocaleString('pt-BR', { minimumFractionDigits: 2 })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/constants.scss';
@import '@/styles/card.scss';

.overview-card {
  @extend .card;

  padding: 20px 30px;
  display: flex;
  row-gap: 10px;
  * {
    margin: 0;
  }

  &__icon {
    font-size: 3em;
  }

  &__amount {
    font-weight: normal;
    &.primary {
      color: $primary-color;
    }
    &.secondary {
      color: $secondary-color;
    }
    &.danger {
      color: $danger-lighter-color;
    }
  }

  &__description {
    font-size: 12px;
    font-weight: 300;
    width: 80%;
  }
}
</style>
