import api from '@/lib/api'

export default {
  getStates () {
    return api.get('/states')
  },

  getCities (_, { stateId }) {
    return api.get(`/states/${stateId}/cities`)
  }
}
