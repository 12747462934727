<template>
  <main>
    <user-navbar />

    <div class="subcategory-upsert">
      <header class="d-flex justify-content-between mb-3">
        <h2>Subcategoria</h2>

        <button class="btn remove-button" @click="handleRemoval">
          <i class="bi-trash" />
          Remover
        </button>
      </header>

      <form @submit.prevent="handleSubmit">
        <div class="col-2 mb-5">
          <input-field
            label="Título"
            v-model="subcategory.title"
            data-test="subcategory-title"
            :errorMessage="handleError(this.$v)"
          />
        </div>

        <hr>

        <form-button
          variant="primary"
          data-test="form-button"
        >
          Salvar
        </form-button>
      </form>
      </div>
  </main>
</template>

<script>
import UserNavbar from '@/components/UserNavbar'
import formErrorHandler from '@/mixins/views/form-error-handler'
import InputField from '@/components/form/InputField'
import FormButton from '@/components/form/FormButton'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { mapActions } from 'vuex'

export default {
  components: { UserNavbar, InputField, FormButton },

  mixins: [formErrorHandler],

  props: {
    id: {
      type: String,
      default: undefined
    },

    categoryId: {
      type: String,
      default: undefined
    }
  },

  data () {
    return {
      subcategory: {
        id: '',
        title: ''
      }
    }
  },

  setup: () => ({ $v: useVuelidate() }),

  validations () {
    return {
      subcategory: {
        title: { required }
      }
    }
  },

  mounted () {
    if (this.isUpdateForm()) {
      this.fetchSubcategory()
    }
  },

  methods: {
    ...mapActions([
      'createSubcategory',
      'getSubcategory',
      'removeSubcategory',
      'updateSubcategory'
    ]),

    async fetchSubcategory () {
      try {
        const { data } = await this.getSubcategory(this.id)
        this.subcategory = data.subcategory
      } catch (error) {
        this.$toast.error('Houve algo de errado, por favor tente novamente mais tarde.')
      }
    },

    successMessage () {
      if (this.isUpdateForm()) { return 'Subcategoria alterada com sucesso.' }

      return 'Subcategoria cadastrada com sucesso.'
    },

    async handleSubmit () {
      const isValid = await this.$v.$validate()
      if (!isValid) { return }

      try {
        await this.handleRequestMethod()
        this.$toast.success(this.successMessage())
        this.$router.push({ name: 'category-show', params: { id: this.categoryId } })
      } catch (error) {
        this.handleResponseError(error)
      }
    },

    handleRequestMethod () {
      if (this.isUpdateForm()) { return this.updateSubcategory(this.payload()) }

      return this.createSubcategory(this.payload())
    },

    payload () {
      return {
        id: this.id,
        title: this.subcategory.title,
        category_id: this.categoryId
      }
    },

    async handleRemoval () {
      if (confirm('Tem certeza?')) {
        try {
          await this.removeSubcategory(this.id)
          this.$toast.success('Subcategoria removida com sucesso.')
          this.$router.push({ name: 'category-list' })
        } catch (error) {
          this.$toast.error('Houve algo de errado, por favor tente novamente mais tarde.')
        }
      }
    },

    isUpdateForm () {
      return this.id !== undefined
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/constants.scss';
  .subcategory-upsert {
    padding: $form-page-padding;

    .remove-button {
       color: $danger-color !important;
       background-color: transparent !important;
       border: 1px solid $danger-dark-color !important;
       &:hover {
         border: 1px solid $danger-color !important;
        }
        &:disabled {
          color: rgba($color: white, $alpha: 0.6);
        }
     }
  }
</style>
