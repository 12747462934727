<template>
  <main class="user-upsert">
    <user-navbar />
    <div class="user-upsert__form">
      <form @submit.prevent="handleSubmit()">
        <h2 class="mb-3">Usuário</h2>
        <h5 class="mb-2">Dados gerais</h5>
        <div class="col-2 mb-5">
          <input-field
            label="Nome"
            v-model="name"
            :errorMessage="handleError(this.$v)"
            data-test="name"
          />

          <select-field
            label="Tipo"
            :options="kindOptions"
            v-model="kind"
            :errorMessage="handleError(this.$v)"
            data-test="kind"
          />

          <input-field
            label="E-mail"
            v-model="email"
            type="email"
            :errorMessage="handleError(this.$v)"
            data-test="email"
          />
        </div>

        <hr>

        <form-button
          variant="primary"
          data-test="form-button"
        >
          Salvar
        </form-button>
      </form>
    </div>
  </main>
</template>

<script>
import UserNavbar from '@/components/UserNavbar.vue'
import InputField from '@/components/form/InputField.vue'
import SelectField from '@/components/form/SelectField.vue'
import FormButton from '@/components/form/FormButton.vue'
import { email, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import formErrorHandler from '@/mixins/views/form-error-handler'
import { mapActions } from 'vuex'

export default {
  components: {
    UserNavbar,
    InputField,
    SelectField,
    FormButton
  },

  mixins: [formErrorHandler],

  props: {
    id: {
      type: String,
      default: undefined
    }
  },

  data () {
    return {
      name: '',
      kind: '',
      email: ''
    }
  },

  mounted () {
    if (this.id !== undefined) {
      this.getUser(this.id)
        .then(response => {
          const user = response.data.user
          this.name = user.name
          this.kind = user.kind
          this.email = user.email
        })
        .catch(_ => {
          this.$toast.error('Houve algo de errado, por favor tente novamente mais tarde.')
        })
    }
  },

  setup: () => ({ $v: useVuelidate() }),

  validations () {
    return {
      email: { required, email },
      name: { required },
      kind: { required }
    }
  },

  computed: {
    kindOptions () {
      return [
        { label: 'Administrador', value: 'admin' },
        { label: 'Colaborador', value: 'colaborator' }
      ]
    },

    successMessage () {
      if (this.id !== undefined) { return 'Usuário editado com sucesso.' }

      return 'Usuário cadastrado com sucesso.'
    }
  },

  methods: {
    ...mapActions(['getUser', 'createUser', 'updateUser']),

    async handleSubmit () {
      const isValid = await this.$v.$validate()
      if (!isValid) { return }

      this.handleRequestMethod()
        .then(_ => {
          this.$toast.success(this.successMessage)
          this.$router.push({ name: 'user-list' })
        }).catch(error => {
          this.handleResponseError(error)
        })
    },

    handleRequestMethod () {
      if (this.id !== undefined) { return this.updateUser(this.userPayload()) }

      return this.createUser(this.userPayload())
    },

    userPayload () {
      return {
        id: this.id,
        email: this.email,
        name: this.name,
        kind: this.kind
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .user-upsert {
    &__form {
      padding: 2%;
    }
  }
</style>
