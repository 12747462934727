<template>
  <modal-dialog
    title="Alertas"
    :show="show"
    @modal-close="$emit('close-alerts-modal')"
    size="large"
  >
    <template v-slot:content>

      <div class="d-flex flex-row-reverse">
        <a
          class="alerts-modal__link mb-4"
          href="#"
          @click="handleAlertKindClick"
          data-test="switch-alerts"
        >
          {{ alertsKindText }} <i class="bi-chevron-right" />
        </a>
      </div>

      <div>
        <table class="table table-borderless text-center">
          <tbody>
            <tr
              v-for="alert in alerts"
              :key="alert.id"
              class="alert"
            >
              <td>
                <span data-test="code">
                  {{alert.project.code}}
                </span>
              </td>

              <td>
                <span
                  class="alerts-modal__alert-kind"
                  data-test="kind"
                >
                  {{alert.translatedKind}}
                </span>
              </td>

              <td>
                <span
                  :class="referenceDateColor(alert.referenceDate)"
                  data-test="reference-date"
                >
                  {{alert.formattedReferenceDate}}
                </span>
              </td>

              <td>
                <button
                  class="btn btn-outline-primary btn-sm"
                  @click="handleChangeStatusClick(alert.id)"
                  data-test="change-status"
                >
                  {{ changeStatusText }}
                </button>
              </td>

              <td>
                <router-link
                  :to="{ name: 'project-show', params: { id: alert.project.id } }"
                  class="alerts-modal__link"
                >
                  ver mais <i class="bi-chevron-right" />
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/ModalDialog.vue'
import { mapActions } from 'vuex'
import formErrorHandler from '@/mixins/views/form-error-handler'

export default {
  name: 'AlertsModal',

  mixins: [formErrorHandler],

  components: {
    ModalDialog
  },

  props: {
    show: {
      type: Boolean,
      required: true
    },

    displayedAlerts: {
      type: Array,
      required: true
    },

    mutedAlerts: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      alertsRendered: 'displayed'
    }
  },

  computed: {
    alertsKindText () {
      if (this.alertsRendered === 'muted') { return 'Alertas ativos' }

      return 'Alertas silenciados'
    },

    alerts () {
      if (this.alertsRendered === 'muted') { return this.mutedAlerts }

      return this.displayedAlerts
    },

    newStatus () {
      if (this.alertsRendered === 'muted') { return 'displayed' }

      return 'muted'
    },

    changeStatusText () {
      if (this.alertsRendered === 'muted') { return 'Reativar' }

      return 'Silenciar'
    }
  },

  methods: {
    ...mapActions(['updateAlert']),
    referenceDateColor (referenceDate) {
      if (new Date(referenceDate) > new Date()) { return 'alerts-modal__text-primary' }

      return 'alerts-modal__text-danger'
    },

    handleAlertKindClick () {
      if (this.alertsRendered === 'displayed') {
        this.alertsRendered = 'muted'
      } else {
        this.alertsRendered = 'displayed'
      }
    },

    handleChangeStatusClick (alertId) {
      if (confirm('Tem certeza?')) {
        this.updateAlert({ id: alertId, status: this.newStatus })
          .then(_response => {
            this.$toast.success('Status alterado com sucesso.')
            this.$emit('close-alerts-modal-and-reload-alerts')
          })
          .catch(error => this.handleResponseError(error))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/constants.scss';

  .alerts-modal {
    &__alert-kind {
      color: $gray-light-color;
    }

    &__link {
      color: $secondary-color;
      text-decoration: none;
    }

    &__link:hover {
      color: $secondary-dark-color;
    }

    &__text-primary {
      color: $secondary-light-color;
    }

    &__text-danger {
      color: $danger-lighter-color;
    }
  }
</style>
