<template>

  <main class="category-list">
    <user-navbar />

    <div class="category-list__card-list">

      <div class="category-list__new-category-link">
        <router-link
          :to="{ name: 'category-new' }"
        >
          <i class="bi-plus" />
          <br />Adicionar nova categoria
        </router-link>
      </div>

      <category-card
        v-for="category in categoryList"
        :key="category.id"
        :category="category"
        class="category-list__card"
      />

    </div>
  </main>
</template>

<script>
import UserNavbar from '@/components/UserNavbar'
import CategoryCard from '@/components/CategoryCard'
import { mapActions } from 'vuex'

export default {
  components: { UserNavbar, CategoryCard },

  data () {
    return {
      categoryList: []
    }
  },

  mounted () {
    this.fetchCategories()
  },

  methods: {
    ...mapActions(['listCategories']),
    async fetchCategories () {
      try {
        const { data } = await this.listCategories()

        this.categoryList = data.categories.map(c => c.category)
      } catch (error) {
        console.error(error)
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/styles/constants.scss';
@import '@/styles/card.scss';

  .category-list {
    &__card-list {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: repeat(auto-fill, minmax($card-list-column-min, $card-list-column-max));
      grid-gap: $card-list-grid-gap;
      margin: $card-list-margin;
      justify-items: center;
    }

    &__card {
      width: 100%;
      align-self: center;
      justify-self: center;
    }

    &__new-category-link {
      @extend .card;
      background-color: transparent;
      width: 100%;
      align-self: center;
      justify-self: center;

      a {
        // font-size: 1.2em;
        color: $gray-lighter-color !important;
        text-align: center;
        text-decoration: none;
        cursor: pointer;

        i {
          font-size: 2em;
        }
      }

    a:hover {
      color: $secondary-color !important;
    }
    }

  }
</style>
