export default function buildProject (params) {
  return {
    id: params.id || '',
    clientName: params?.client?.name || '',
    code: params.code || '',
    finishDate: params.finish_date || '',
    status: params.status || '',

    get translatedStatus () {
      if (this.status === '') { return '' }
      if (this.status === 'pending') { return 'Não iniciado' }
      if (this.status === 'started') { return 'Em andamento' }
      if (this.status === 'finished') { return 'Finalizado' }

      return ''
    },

    get formattedFinishDate () {
      if (this.finishDate === '') { return '' }

      const date = this.finishDate.split('T')[0].split('-')
      return `${date[2]}/${date[1]}/${date[0]}`
    }
  }
}
