import api from '@/lib/api'

export default {

  listAlerts (_, { status }) {
    return api.get(`/alerts?status=${status}`)
  },

  updateAlert (_, alert) {
    return api.put(`/alerts/${alert.id}`, { alert })
  }
}
