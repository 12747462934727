import api from '@/lib/api'

export default {
  getProjectsByStatusReport (_, { filterBy, yearReference, monthReference }) {
    return api.get(`reports/projects_by_status?filter_by=${filterBy}&year_reference=${yearReference}&month_reference=${monthReference}`)
  },

  getProductsByCategoryReport (_, { filterBy, yearReference, monthReference }) {
    return api.get(`reports/products_by_category?filter_by=${filterBy}&year_reference=${yearReference}&month_reference=${monthReference}`)
  },

  getProductsByStatusReport (_, { filterBy, yearReference, monthReference }) {
    return api.get(`reports/products_by_status?filter_by=${filterBy}&year_reference=${yearReference}&month_reference=${monthReference}`)
  },

  getBudgetsStatusByDateReport (_, { filterBy, yearReference, monthReference }) {
    return api.get(`reports/budgets_status_by_date?filter_by=${filterBy}&year_reference=${yearReference}&month_reference=${monthReference}`)
  },

  getbudgetsByStatusReport (_, { filterBy, yearReference, monthReference }) {
    return api.get(`reports/budgets_by_status?filter_by=${filterBy}&year_reference=${yearReference}&month_reference=${monthReference}`)
  },

  getProductsByClientPositionReport (_, { filterBy, yearReference, monthReference }) {
    return api.get(`reports/products_by_client_position?filter_by=${filterBy}&year_reference=${yearReference}&month_reference=${monthReference}`)
  },

  getClientsIncomeByPositionReport (_, { filterBy, yearReference, monthReference }) {
    return api.get(`reports/clients_income_by_position?filter_by=${filterBy}&year_reference=${yearReference}&month_reference=${monthReference}`)
  }
}
