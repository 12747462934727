<template>
  <main>
    <user-navbar class="mb-4" />

    <div class="container">
      <div class="d-flex justify-content-between mb-4 row">
        <h1 class="col-7">
          Relatórios
        </h1>
        <div class="row col-5">
          <div class="col-2">
            <button
              @click="handleGeneralButtonClick"
              class="btn"
              :class="generalButtonStyle"
            >
              Geral
            </button>
          </div>

          <div class="col-5">
            <select
              v-model="filter.month"
              class="form-select"
              @change="handleMonthChange"
            >
              <option
                v-for="month in monthOptions"
                :key="month.value"
                :value="month.value"
              >
                {{ month.label }}
              </option>
            </select>
          </div>

          <div class="col-5">
            <select
              class="form-select"
              v-model="filter.year"
              @change="handleYearChange"
            >
              <option
                v-for="year in yearOptions"
                :key="year.value"
                :value="year.value"
              >
                {{ year.label }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div>
        <div class="row col-12 d-flex justify-content-between mb-3">
          <projects-by-status class="col-4" />
          <products-by-category class="col-3" />
          <products-by-status class="col-4" />
        </div>

        <div class="row col-12 d-flex justify-content-between mb-3">
          <budgets-status-by-date class="col-5" />
          <products-by-client-position class="col-7" />
        </div>

        <div class="row col-12 d-flex justify-content-between mb-3">
          <budget-by-status-vue class="col-4" />
          <clients-income-by-position class="col-7" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import UserNavbar from '@/components/UserNavbar'
import ProjectsByStatus from '@/components/reports/ProjectsByStatus'
import ProductsByCategory from '@/components/reports/ProductsByCategory'
import ProductsByStatus from '@/components/reports/ProductsByStatus'
import BudgetsStatusByDate from '@/components/reports/BudgetsStatusByDate'
import BudgetByStatusVue from '@/components/reports/BudgetByStatus.vue'
import ProductsByClientPosition from '@/components/reports/ProductsByClientPosition'
import ClientsIncomeByPosition from '@/components/reports/ClientsIncomeByPosition'
import { mapGetters, mapMutations } from 'vuex'
import { monthsOptions, yearsOptions } from '@/enums/dates'

export default {
  name: 'ReportsView',

  components: {
    BudgetByStatusVue,
    ProductsByCategory,
    ProjectsByStatus,
    ProductsByStatus,
    BudgetsStatusByDate,
    ProductsByClientPosition,
    ClientsIncomeByPosition,
    UserNavbar
  },

  data () {
    return {
      filter: {
        month: null,
        year: null
      }
    }
  },

  computed: {
    generalButtonStyle () {
      if (this.getFilterBy() === 'general') { return 'btn-dark' }

      return 'btn-outline-dark'
    },

    // NOTE: return a list from initial application year until current year plus one to cover
    // cases when a project is contracted to next year.
    yearOptions () {
      return [
        {
          label: 'Ano',
          value: null
        },
        ...yearsOptions(2022, 1)
      ]
    },

    monthOptions () {
      return [
        {
          value: null,
          label: 'Mês'
        },
        ...monthsOptions()
      ]
    }
  },

  methods: {
    ...mapGetters(['getFilterBy', 'getYearReference', 'getMonthReference']),
    ...mapMutations(['setFilterBy', 'setYearReference', 'setMonthReference']),

    handleYearChange () {
      this.setFilterBy('year')
      this.setYearReference(this.filter.year)
      this.setMonthReference(this.filter.month)
    },

    handleMonthChange () {
      if (this.getYearReference() === null) {
        this.$toast.warning('Selecione o mês e ano para que o filtro seja aplicado')
        return
      }

      this.setFilterBy('month')
      this.setMonthReference(this.filter.month)
    },

    handleGeneralButtonClick () {
      this.setFilterBy('general')
      this.setMonthReference(null)
      this.setYearReference(null)
      this.filter.month = null
      this.filter.year = null
    }
  }
}
</script>
