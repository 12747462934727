<template>
  <main>
    <div class="tip">
      <span class="tip__text">{{ text }}</span>
      <i class="bi" :class="icon" data-hover="Descrição do gráfico!"/>
    </div>
  </main>
</template>

<script>
export default {
  name: 'ToolTip',

  props: {
    text: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/constants.scss';
.tip {
    display: inline-block;
    margin-left: 1em;
    position: relative;

    &__text {
      background-color: $gray-dark-color;
      border-radius: 1em;
      bottom: 100%;
      color: $gray-lighter-color;
      left: 50%;
      padding: 1em;
      position: absolute;
      text-transform: none;
      visibility: hidden;
      z-index: 1;
      width: 20em;
    }

    &:hover .tip__text {
      visibility: visible;
    }
  }
</style>
