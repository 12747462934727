<template>
  <main class="budget-upsert">
    <user-navbar />
      <div class="budget-upsert__form-overview">
        <div class="d-flex justify-content-between w-100 mb-4">
          <div>
            <h2 v-if="!revision" data-test="budget-upsert-title">Orçamento</h2>
            <h2 v-else data-test="budget-upsert-title">Orçamento - Histórico</h2>
            <h5 class="budget-upsert__project-code" data-test="budget-project-code">{{ budget.project.code }}</h5>
          </div>

          <div v-if="!viewOnly()" class="d-flex justify-content-between align-content-start flex-wrap" data-test="budget-action-buttons">
            <button
              title="Duplicar orçamento"
              class="btn duplicate-button"
              @click="handleDuplication()"
            >
              <i class="bi-files" />
            </button>
            <button
              title="Excluir orçamento"
              class="btn action-button mx-2"
              @click="handleRemoval()"
            >
              <i class="bi-trash-fill"
              aria-label="Duplicar orçamento"
              />
            </button>
          </div>
        </div>
        <div class="d-flex justify-content-between w-100">
          <form class="budget-upsert__form col-4" @submit.prevent="handleSubmit()">
            <div class="col-8 mb-5 px-2">
                <date-field
                  label="Data de início"
                  v-model="budget.start_date"
                  data-test="budget-start-date"
                  :errorMessage="handleError($v)"
                />

                <date-field
                  label="Data de encerramento"
                  v-model="budget.finish_date"
                  data-test="budget-finish-date"
                  :errorMessage="handleError($v)"
                />

                <select-field
                  label="Status"
                  :options="status"
                  v-model="budget.status"
                  :errorMessage="handleError(this.$v)"
                  data-test="budget-status"
                />
            </div>

          <div class="d-flex justify-content-end">
            <form-button
              v-if="!viewOnly()"
              variant="primary"
              data-test="form-button"
              class="px-5"
            >Salvar</form-button>
          </div>
        </form>

        <div class="budget-upsert-overview py-2">
          <project-overview :project="budget"  :showDuration="false"/>
        </div>
      </div>

      <template v-if="isUpdateForm()">
        <budget-revisions-table :budgetId="budget.id" :revisions="revisions()" :viewOnly="viewOnly()" class="my-3"/>
        <div  class="product-list mt-2" data-test="budget-product-list">
          <h3>Serviços</h3>
          <product-list :products="products" :budgetId="budget.id" :showCreateProductButton="!viewOnly()" />
        </div>

        <div class="payment-list mt-2" data-test="budget-payment-list">
          <h3>Pagamentos</h3>
          <payment-list :payments="payments" :budgetId="budget.id" :showCreatePaymentButton="!viewOnly()" :activePaymentId="paymentId" />
        </div>
      </template>
    </div>

  </main>
</template>

<script>
import UserNavbar from '@/components/UserNavbar'
import DateField from '@/components/form/DateField'
import SelectField from '@/components/form/SelectField'
import ProjectOverview from '@/components/ProjectOverview'
import FormButton from '@/components/form/FormButton'
import ProductList from '@/views/private/products/ProductList'
import PaymentList from '@/views/private/payments/PaymentList'
import BudgetRevisionsTable from '@/components/budgets/BudgetRevisionsTable'
import formErrorHandler from '@/mixins/views/form-error-handler'
import { useVuelidate } from '@vuelidate/core'
import { budgetStatusOptions } from '@/enums/status'
import { required } from '@vuelidate/validators'
import { validDate } from '@/validators/index'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  components: { UserNavbar, SelectField, FormButton, DateField, ProjectOverview, ProductList, PaymentList, BudgetRevisionsTable },

  mixins: [formErrorHandler],

  props: {
    id: {
      type: String,
      default: undefined
    },

    project_id: {
      type: String,
      default: undefined
    },

    paymentId: {
      type: String,
      default: undefined
    },

    projectStartDate: {
      type: String,
      default: undefined
    },

    projectFinishDate: {
      type: String,
      default: undefined
    },

    revision: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      status: budgetStatusOptions()
    }
  },

  setup: () => ({ $v: useVuelidate() }),

  validations () {
    return {
      budget: {
        start_date: { required, validDate },
        finish_date: { validDate },
        status: { required }
      }
    }
  },

  mounted () {
    if (this.isUpdateForm()) {
      try {
        this.getBudget(this.id)
      } catch (error) {
        this.handleResponseError(error)
      }
    }

    if (!this.isUpdateForm()) {
      this.$store.commit('setBudgetDate', {
        startDate: this.projectStartDate,
        finishDate: this.projectFinishDate
      })
    }

    if (this.budget.status === '') { this.budget.status = 'negotiation' }
  },

  computed: {
    ...mapState(['budget']),

    products () {
      return this.budget.products.map(p => p.product)
    },

    payments () {
      return this.budget.payments.map(p => p.payment)
    }

  },

  methods: {
    ...mapActions(['getBudget', 'removeBudget', 'createBudget', 'updateBudget', 'duplicateBudget']),
    ...mapMutations(['resetBudget']),

    revisions () {
      return this.budget.revisions.map(p => p.revision)
    },

    handleRequestMethod () {
      if (this.isUpdateForm()) { return this.updateBudget(this.payload()) }

      return this.createBudget(this.payload())
    },

    successMessage () {
      if (this.isUpdateForm()) { return 'Orçamento editado com sucesso.' }

      return 'Orçamento cadastrado com sucesso.'
    },

    async handleSubmit () {
      const isValid = await this.$v.$validate()

      if (!isValid) { return }

      try {
        const message = this.successMessage()
        await this.handleRequestMethod()
        this.$toast.success(message)
      } catch (error) {
        this.handleResponseError(error)
      }
    },

    isUpdateForm () {
      return this.id !== undefined || this.budget.id !== ''
    },

    viewOnly () {
      return !!this.revision
    },

    payload () {
      return {
        id: this.budget.id,
        start_date: this.budget.start_date,
        finish_date: this.budget.finish_date,
        status: this.budget.status,
        project_id: this.project_id || this.budget.project.id
      }
    },

    async handleRemoval () {
      if (confirm('Tem certeza?')) {
        try {
          const projectId = this.budget.project.id
          await this.removeBudget(this.budget.id)
          this.$toast.success('Orçamento removido com sucesso.')
          this.$router.push({ name: 'project-show', params: { id: projectId } })
        } catch (error) {
          this.$toast.error('Houve algo de errado, por favor tente novamente mais tarde.')
        }
      }
    },

    async handleDuplication () {
      if (confirm('Deseja duplicar o orçamento?')) {
        try {
          const budgetId = this.budget.id
          const { data } = await this.duplicateBudget(budgetId)
          this.$toast.success('Orçamento duplicado com sucesso.')
          this.$router.push({ name: 'budget-edit', params: { id: data.id } })
        } catch (error) {
          this.$toast.error('Houve algo de errado, por favor tente novamente mais tarde.')
        }
      }
    }
  },

  beforeRouteUpdate (to, _, next) {
    this.getBudget(to.params.id)
    next()
  },

  beforeDestroy () {
    this.resetBudget()
  }
}
</script>

<style lang="scss" scoped>
@import 'bootstrap/dist/css/bootstrap';
@import '@/styles/constants.scss';

  .budget-upsert {
    &__form-overview {
      margin-top: 1.2em;
      padding: 2em;
    }

    // TODO: use form button component here
    .action-button {
      color: $danger-color !important;
      background-color: transparent !important;
      border: 1px solid $danger-dark-color !important;
      &:hover {
        border: 1px solid $danger-color !important;
      }
      &:disabled {
        color: rgba($color: white, $alpha: 0.6);
      }
    }

    .duplicate-button {
      color: $secondary-color !important;
      background-color: transparent !important;
      border: 1px solid $secondary-dark-color !important;
      &:hover {
        border: 1px solid $secondary-color !important;
      }
      &:disabled {
        color: rgba($color: white, $alpha: 0.6);
      }
    }

    &__project-code {
      font-weight: 300;
      padding: 0 10px
    }
  }
</style>
