<template>
  <modal-dialog :show="show" @modal-close="close" title="Serviços" class="product-upsert">
    <template v-slot:content>
      <div class="product-upsert__form">
        <form @submit.prevent="handleSubmit">
          <div class="col-6 mb-5">
            <date-field
              label="Previsão de início"
              v-model="product.estimated_start_date"
              data-test="product-estimated-start-date"
              :errorMessage="handleError($v)"
            />

            <date-field
              label="Previsão de término"
              v-model="product.estimated_finish_date"
              data-test="product-estimated-finish-date"
              :errorMessage="handleError($v)"
            />

            <date-field
              v-if="product.status === 'started' || product.status === 'finished'"
              label="Data de início"
              v-model="product.start_date"
              data-test="product-start-date"
              :errorMessage="handleError($v)"
            />

            <date-field
              v-if="product.status === 'finished'"
              label="Data de término"
              v-model="product.finish_date"
              data-test="product-finish-date"
              :errorMessage="handleError($v)"
            />

            <select-field
              label="Categoria"
              v-model="product.category_id"
              :options="categoryOptions"
              @input="fetchSubcategories"
              data-test="project-category"
              :errorMessage="handleError($v)"
            />

            <select-field
              label="Subcategoria"
              v-model="product.subcategory_id"
              :options="subcategories"
              data-test="project-subcategory"
              :errorMessage="handleError($v)"
            />

            <select-field
              label="Status"
              v-model="product.status"
              :options="productStatus"
              data-test="product-status"
              :errorMessage="handleError($v)"
            />

            <input-field
              v-model="product.estimated_workload"
              label="Carga horária prevista"
              type="number"
              data-test="product-estimated-workload"
              :errorMessage="handleError($v)"
            />

            <input-field
              v-if="product.status === 'finished'"
              v-model="product.workload"
              label="Carga horária"
              type="number"
              data-test="product-workload"
              :errorMessage="handleError($v)"
            />

            <input-field
              v-model="product.hour_price"
              label="Preço/hora"
              type="number"
              data-test="product-hour-price"
              :errorMessage="handleError($v)"
              step="0.01"
            />

            <text-area-field
              label="Descrição"
              v-model="product.description"
              data-test="product-description"
            />
          </div>

          <div v-if="budgetId !== undefined" class="d-flex justify-content-between flex-row-reverse">
            <form-button
              variant="primary"
              data-test="form-button"
              class="px-5"
            >Salvar
            </form-button>
            <button @click="handleRemoval" class="btn remove-button">Remover</button>
          </div>
        </form>

      </div>
    </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/ModalDialog'
import InputField from '@/components/form/InputField'
import DateField from '@/components/form/DateField'
import SelectField from '@/components/form/SelectField'
import TextAreaField from '@/components/form/TextAreaField'
import FormButton from '@/components/form/FormButton'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { validDate } from '@/validators/index'
import { productStatusOptions } from '@/enums/status'
import formErrorHandler from '@/mixins/views/form-error-handler'
import { mapActions } from 'vuex'

export default {
  components: { ModalDialog, InputField, DateField, SelectField, FormButton, TextAreaField },

  mixins: [formErrorHandler],

  props: {
    show: {
      type: Boolean,
      default: true
    },

    budgetId: {
      type: String,
      default: undefined
    },

    id: {
      type: String,
      default: undefined
    }
  },

  data () {
    return {
      product: {
        estimated_workload: '',
        estimated_start_date: '',
        estimated_finish_date: '',
        workload: null,
        start_date: '',
        finish_date: '',
        hour_price: null,
        status: '',
        description: '',
        budget_id: '',
        subcategory_id: '',
        category_id: '',
        subcategory: ''
      },
      categories: [],
      subcategories: [],
      productStatus: productStatusOptions()
    }
  },

  setup: () => ({ $v: useVuelidate() }),

  validations () {
    const product = {
      estimated_start_date: { required, validDate },
      estimated_finish_date: { required, validDate },
      estimated_workload: { required },
      hour_price: { required },
      subcategory_id: { required },
      category_id: { required }
    }
    if (this.isUpdateForm() && this.product.status === 'finished') {
      product.workload = { required }
      product.start_date = { required, validDate }
      product.finish_date = { required, validDate }
    }

    return {
      product
    }
  },

  async mounted () {
    await this.fetchCategories()

    if (this.isUpdateForm()) {
      this.fetchProduct().then(() => this.fetchSubcategories())
    } else {
      this.setProductDefaultDate()
    }

    if (this.product.status === '') { this.product.status = 'pending' }
  },

  computed: {
    categoryOptions () {
      return this.categories.map(sc => ({
        value: sc.id,
        label: sc.title
      }))
    }
  },

  methods: {
    ...mapActions([
      'createProduct',
      'getBudget',
      'getProduct',
      'listCategories',
      'removeProduct',
      'updateProduct'
    ]),

    isUpdateForm () {
      return this.id !== undefined
    },

    handleRequestMethod () {
      if (this.isUpdateForm()) { return this.updateProduct(this.payload()) }

      return this.createProduct(this.payload())
    },

    successMessage () {
      if (this.isUpdateForm()) { return 'Serviço alterado com sucesso.' }

      return 'Serviço cadastrado com sucesso.'
    },

    async handleSubmit () {
      const isValid = await this.$v.$validate()

      if (!isValid) { return }

      try {
        await this.handleRequestMethod()
        this.$toast.success(this.successMessage())
        this.close()
        this.getBudget(this.budgetId)
      } catch (error) {
        this.handleResponseError(error)
      }
    },

    payload () {
      return {
        id: this.product.id,
        start_date: this.product.start_date,
        estimated_start_date: this.product.estimated_start_date,
        finish_date: this.product.finish_date,
        estimated_finish_date: this.product.estimated_finish_date,
        estimated_workload: this.product.estimated_workload,
        workload: this.product.workload,
        hour_price: this.product.hour_price,
        status: this.product.status,
        description: this.product.description,
        subcategory_id: this.product.subcategory_id,
        budget_id: this.product.budget_id || this.budgetId
      }
    },

    async handleRemoval () {
      if (confirm('Tem certeza?')) {
        try {
          await this.removeProduct(this.product.id)
          this.$toast.success('Produto removido com sucesso.')
          this.close()
          this.getBudget(this.budgetId)
        } catch (error) {
          this.$toast.error('Houve algo de errado, por favor tente novamente mais tarde.')
        }
      }
    },

    async fetchCategories () {
      try {
        const response = await this.listCategories()
        const { categories } = response.data
        const blankSpace = [{ value: undefined, label: '' }]
        this.categories = [blankSpace, ...categories.map(({ category }) => {
          return {
            id: category.id,
            title: category.title,
            subcategories: category.subcategories.map(({ subcategory }) => ({
              id: subcategory.id,
              title: subcategory.title
            }))
          }
        })]
      } catch (error) {
        this.$toast.error('Houve algo de errado, por favor tente novamente mais tarde.')
      }
    },

    fetchSubcategories () {
      const blankSpace = { value: undefined, label: '' }
      if (this.product.category_id === '') { this.subcategories = [blankSpace]; return }

      const selectedCategory = this.categories.find(c => c.id === this.product.category_id)

      if (selectedCategory === undefined) { this.subcategories = [blankSpace]; return }

      this.subcategories = [blankSpace, ...selectedCategory.subcategories.map(e => ({
        value: e.id,
        label: e.title
      }))]
    },

    async fetchProduct () {
      try {
        const { data } = await this.getProduct(this.id)
        this.product = data.product
        this.product.category_id = data.product.category.id
        this.product.subcategory_id = data.product.subcategory.id
      } catch (error) {
        this.$toast.error('Houve algo de errado, por favor tente novamente mais tarde.')
      }
    },

    close () {
      this.$emit('product-upsert-close')
    },

    setProductDefaultDate () {
      const budget = this.$store.state.budget

      this.product.estimated_start_date = budget.start_date
      this.product.estimated_finish_date = budget.finish_date
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/constants.scss';
.remove-button {
  color: white !important;
  background-color: $danger-color !important;
  &:hover {
    background-color: $danger-dark-color !important;
  }
  &:disabled {
    color: rgba($color: white, $alpha: 0.6);
  }
}
</style>
