<template>
  <div class="user-alerts">
    <alerts-button
      class="user-alerts__button"
      :alertsCount="displayedAlerts.length"
      @open-alerts-modal="showModal = true"
      data-test="alerts-button"
    />

    <alerts-modal
      :displayedAlerts="displayedAlerts"
      :mutedAlerts="mutedAlerts"
      :show="showModal"
      @close-alerts-modal="showModal = false"
      @close-alerts-modal-and-reload-alerts="handleCloseAndReloadAlerts"
      data-test="alerts-modal"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import loadErrorHandler from '@/mixins/views/load-error-handler'
import buildAlert from '@/builders/alertBuilder'
import AlertsButton from '@/components/alerts/AlertsButton.vue'
import AlertsModal from '@/components/alerts/AlertsModal.vue'

export default {
  name: 'UserAlerts',

  components: {
    AlertsButton,
    AlertsModal
  },

  mixins: [loadErrorHandler],

  data () {
    return {
      displayedAlerts: [],
      mutedAlerts: [],
      showModal: false
    }
  },

  mounted () {
    this.fetchAlerts()
  },

  methods: {
    ...mapActions(['listAlerts']),

    fetchAlerts () {
      this.listAlerts({ status: 'displayed' })
        .then(response => {
          this.displayedAlerts = response.data.alerts.map(({ alert }) => buildAlert(alert))
        })
        .catch(error => this.handleLoadError(error))

      this.listAlerts({ status: 'muted' })
        .then(response => {
          this.mutedAlerts = response.data.alerts.map(({ alert }) => buildAlert(alert))
        })
        .catch(error => this.handleLoadError(error))
    },

    handleCloseAndReloadAlerts () {
      this.showModal = false
      this.fetchAlerts()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "bootstrap/dist/css/bootstrap";

.user-alerts {
  @extend .col-10, .offset-1, .d-flex, .flex-row-reverse;
}
</style>
