<template>
  <main>
    <user-navbar />
    <div class="user-show">
      <h2 class="mb-3">Usuário</h2>
      <h5 class="mb-2">Dados gerais</h5>
      <div class="col-2 mb-5">
        <answered-field
          label="Nome"
          :value="user.name"
          dataTest="user-name"
        />

        <answered-field
          label="Tipo"
          :value="userKind"
          dataTest="user-kind"
        />

        <answered-field
          label="E-mail"
          :value="user.email"
          dataTest="user-email"
        />
      </div>
      <hr>
      <div class="user-show__options">
        <router-link
          :to="{ name: 'user-edit', params: { id: id } }"
          class="user-show__edit-link"
        >
          <i class="bi-pencil-fill" /> Editar
        </router-link>
        <button
          class="user-show__remove-button"
          @click="handleRemoval"
        >
          <i class="bi-trash-fill" />
          Remover
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import UserNavbar from '@/components/UserNavbar.vue'
import AnsweredField from '@/components/form/AnsweredField.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    UserNavbar,
    AnsweredField
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      user: {
        name: '',
        kind: '',
        email: ''
      }
    }
  },

  mounted () {
    this.getUser(this.id)
      .then(response => {
        this.user = response.data.user
      })
      .catch(_ => {
        this.$toast.error('Houve algo de errado, por favor tente novamente mais tarde.')
      })
  },

  computed: {
    userKind () {
      if (this.user.kind === 'admin') { return 'Administrador' }

      return 'Colaborador'
    }
  },

  methods: {
    ...mapActions(['removeUser', 'getUser']),

    handleRemoval () {
      if (confirm('Tem certeza ?')) {
        this.removeUser(this.id)
          .then(_ => {
            this.$toast.success('Usuário removido com sucesso.')
            this.$router.push({ name: 'user-list' })
          })
          .catch(_ => {
            this.$toast.error('Houve algo de errado, por favor tente novamente mais tarde.')
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'bootstrap/dist/css/bootstrap';
@import '@/styles/constants.scss';

  .user-show {
    padding: 2em;

    &__edit-link {
      @extend .btn;
      color: white;
      background-color: $secondary-color;
    }

    &__edit-link:hover {
      color: white !important;
      background-color: $secondary-dark-color;
    }

    &__remove-button {
      @extend .btn;
      @extend .btn-danger;
    }

    &__options {
      display: flex;
      justify-content: space-between;
    }
  }
</style>
