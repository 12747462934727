<template>
  <main class="client-upsert">
    <user-navbar />

    <div class="client-upsert__form">
      <form @submit.prevent="handleSubmit()">
        <h3 class="mb-3">Clientes</h3>
        <h5 class="mb-2">Dados gerais</h5>

        <div class="col-4 mb-5">
          <input-field
            label="Nome"
            v-model="client.name"
            :errorMessage="handleError(this.$v)"
            data-test="client-name"
          />

          <div class="row">
            <div class="col">
              <input-field
                label="Instituição"
                v-model="client.institution.name"
                :errorMessage="handleError(this.$v)"
                data-test="client-institution-name"
              />
            </div>

            <div class="col">
              <select-field
                label="Tipo de Instituição"
                :options="institutionKindOptions"
                v-model="client.institution.kind"
                :errorMessage="handleError(this.$v)"
                data-test="client-institution-kind"
                />
            </div>
          </div>

          <select-field
            label="Cargo"
            :options="positions"
            v-model="client.position"
            :errorMessage="handleError(this.$v)"
            data-test="client-position-kind"
          />

          <input-field
            label="Telefone"
            v-model="client.phone"
            :errorMessage="handleError(this.$v)"
            data-test="client-phone"
          />

          <input-field
            label="E-mail"
            v-model="client.email"
            data-test="client-email"
          />
        </div>

        <hr class="mx-2">

        <h5 class="mb-3">Endereço</h5>

        <div class="col-4 mb-5">
          <input-field
            label="Lougradouro"
            v-model="client.complement"
            data-test="client-complement"
          />

          <input-field
            label="CEP"
            v-model="client.cep"
            data-test="client-cep"
          />

          <select-field
            label="Estado"
            :options="states"
            v-model="client.state"
            @input="fetchCities"
            :errorMessage="handleError(this.$v)"
            v-if="!client.isInternational"
            data-test="client-state"
          />

          <select-field
            label="Cidade"
            :options="cities"
            v-model="client.city"
            :value="client.city"
            :errorMessage="handleError(this.$v)"
            v-if="!client.isInternational"
            data-test="client-city"
          />

          <div class="row d-flex align-items-center">
            <div class="col">
              <input-field
              label="País"
              v-model="client.country"
              :errorMessage="handleError(this.$v)"
              :disabled="!client.isInternational"
              data-test="client-country"
            />
            </div>

            <div class="col">
              <checkbox-field
                label="Internacional"
                name="international"
                v-model="client.isInternational"
                @input="resetStateAndCity"
                data-test="is-international"
              />
            </div>
          </div>
        </div>

        <hr class="mx-2">

        <h4 class="mb-3">Contato</h4>

        <div class="col-4 mb-5">
          <input-field
            label="Mídias Sociais"
            v-model="client.socialMedia"
            data-test="client-social-media"
          />

          <input-field
            label="Website"
            v-model="client.website"
            data-test="client-website"
          />

          <text-area-field
            label="Mais informações"
            v-model="client.moreInfo"
            data-test="client-more-info"
          />
        </div>

        <hr class="mx-2">

        <h5 class="mb-3" @click="fetchStates">Responsáveis</h5>

        <div class="col-4 mb-4">
          <tag-selector
            :items="users"
            label="Usuários"
            v-model="client.users"
            :errorMessage="handleError(this.$v)"
          />
        </div>

        <hr class="mx-2">

        <div class="d-flex justify-content-end">
          <form-button
            variant="primary"
            data-test="form-button"
            class="px-5"
          >
            Salvar
          </form-button>
        </div>

      </form>
    </div>
  </main>
</template>

<script>
import UserNavbar from '@/components/UserNavbar'
import InputField from '@/components/form/InputField'
import SelectField from '@/components/form/SelectField'
import TextAreaField from '@/components/form/TextAreaField'
import TagSelector from '@/components/form/TagSelector'
import FormButton from '@/components/form/FormButton'
import CheckboxField from '@/components/form/CheckboxField'
import formErrorHandler from '@/mixins/views/form-error-handler'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  components: { UserNavbar, InputField, SelectField, TextAreaField, TagSelector, FormButton, CheckboxField },

  mixins: [formErrorHandler],

  props: {
    id: {
      type: String,
      default: undefined
    }
  },

  data () {
    return {
      client: {
        name: '',
        institution: {
          name: '',
          kind: ''
        },
        position: '',
        phone: '',
        email: '',
        complement: '',
        cep: '',
        state: '',
        city: '',
        country: 'Brasil',
        isInternational: false,
        socialMedia: '',
        website: '',
        moreInfo: '',
        users: []
      },
      states: [],
      cities: [],
      users: []
    }
  },
  async mounted () {
    if (this.isUpdateForm()) {
      await this.fetchClient()
      const citiesResponse = await this.getCities({ stateId: this.client.state })

      this.cities = citiesResponse.data.cities.map(({ city }) => {
        return {
          label: city.name,
          value: city.id
        }
      })
    }
  },

  setup: () => ({ $v: useVuelidate() }),

  validations () {
    return {
      client: {
        name: { required },
        institution: {
          name: { required },
          kind: { required }
        },
        position: { required },
        phone: { required },
        // TODO: add conditional validation to city and state
        country: { required },
        users: { required }
      }
    }
  },

  computed: {
    institutionKindOptions () {
      return [
        { label: 'Empresa', value: 'company' },
        { label: 'Universidade', value: 'university' }
      ]
    },

    positions () {
      return [
        { label: 'Aluno de Graduação - TCC', value: 'bachelors_student_tcc' },
        { label: 'Aluno de Graduação - Iniciação Científica', value: 'bachelors_student_ic' },
        { label: 'Aluno de Mestrado', value: 'masters_student' },
        { label: 'Aluno de Residência', value: 'residency_student' },
        { label: 'Aluno de Doutorado', value: 'doctorate_student' },
        { label: 'Professor de Universidade', value: 'university_professor' },
        { label: 'Colaborador - Empresa', value: 'company_colaborator' },
        { label: 'Proprietário - Empresa', value: 'company_owner' },
        { label: 'Sócio - Empresa', value: 'company_partner' }
      ]
    }
  },

  methods: {
    ...mapActions([
      'getCities',
      'allUsers',
      'getClient',
      'getStates',
      'updateClient',
      'createClient'
    ]),

    isUpdateForm () {
      return this.id !== undefined
    },

    successMessage () {
      if (this.id !== undefined) { return 'Cliente editado com sucesso.' }

      return 'Cliente cadastrado com sucesso.'
    },

    handleRequest () {
      if (this.id !== undefined) { return this.updateClient(this.clientPayload()) }

      return this.createClient(this.clientPayload())
    },

    async handleSubmit () {
      const isValid = await this.$v.$validate()
      if (!isValid) { return }

      this.handleRequest().then(_ => {
        this.$toast.success(this.successMessage())
        this.$router.push({ name: 'client-list' })
      }).catch(error => {
        this.handleResponseError(error)
      })
    },

    clientPayload () {
      return {
        id: this.id,
        name: this.client.name,
        kind: this.client.institution.kind,
        institution: this.client.institution.name,
        position: this.client.position,
        phone: this.client.phone,
        email: this.client.email,
        address_attributes: {
          complement: this.client.complement,
          international: this.client.isInternational,
          cep: this.client.cep,
          country: this.client.country,
          city_id: this.client.city
        },
        contact_attributes: {
          social_media: this.client.socialMedia,
          website: this.client.website,
          more_info: this.client.moreInfo
        },
        user_ids: this.client.users.map((user) => user.value)

      }
    },

    async fetchStates () {
      this.getStates().then(({ data }) => {
        this.states = [{ value: undefined, label: '' }]

        this.states = [this.states, ...data.states.map(({ state }) => {
          return {
            label: state.name,
            value: state.id
          }
        })]
      }).catch((error) => {
        this.handleResponseError(error)
      })
    },

    async fetchCities () {
      this.getCities({ stateId: this.client.state }).then(({ data }) => {
        const blankSpace = [{ value: undefined, label: '' }]
        this.cities = [blankSpace, ...data.cities.map(({ city }) => {
          return {
            label: city.name,
            value: city.id
          }
        })]
      }).catch((error) => {
        this.handleResponseError(error)
      })
    },

    async fetchUsers () {
      this.allUsers().then(({ data }) => {
        const blankSpace = [{ value: undefined, label: '' }]
        this.users = [blankSpace, ...data.users.map(({ user }) => {
          return {
            label: user.name,
            value: user.id
          }
        })]

        this.defineLoggedUserAsClientManager()
      }).catch((error) => {
        this.handleResponseError(error)
      })
    },

    async fetchClient () {
      try {
        const clientResponse = await this.getClient(this.id)
        const { client } = clientResponse.data

        this.client.name = client.name
        this.client.institution.name = client.institution
        this.client.institution.kind = client.kind
        this.client.position = client.position
        this.client.phone = client.phone
        this.client.email = client.email
        this.client.complement = client.address.complement
        this.client.cep = client.address.cep
        this.client.isInternational = client.address.international
        this.client.country = client.address.country
        this.client.socialMedia = client.contact.social_media
        this.client.website = client.contact.website
        this.client.moreInfo = client.contact.more_info
        this.client.state = client.address.state.id
        this.client.city = client.address.city.id
        this.client.users = client.users.map(({ user }) => ({ value: user.id, label: user.name }))
      } catch (error) {
        this.$toast.error('Houve algo de errado, por favor tente novamente mais tarde.')
      }
    },

    resetStateAndCity () {
      if (this.client.isInternational) {
        this.client.state = undefined
        this.client.city = undefined
        this.client.country = ''
      } else {
        this.client.country = 'Brasil'
      }
    },

    defineLoggedUserAsClientManager () {
      if (this.isUpdateForm()) return

      const loggedUserId = localStorage.getItem('userId')
      if (!loggedUserId) return

      const loggedUserOption = this.users.find(u => u.value === loggedUserId)

      if (!loggedUserOption) return

      this.client.users.push(loggedUserOption)
    }
  },

  created () {
    this.fetchStates()
    this.fetchUsers()
  }

}
</script>

<style lang="scss" scoped>
  .client-upsert {
    &__form {
      padding: 2% 6%;
    }
  }
</style>
