<template>
  <div class="payments-overview">
    <div class="overview-header">
      <h5>Pagamentos</h5>

      <div class="header-filters d-flex">
        <template v-if="listPayments">
          <form-button
            v-for="(button, index) in status"
            @click="setStatusFilter(button.status)"
            :variant="activeFilterStatus(button.status, button.variant)"
            :key="`bs-${index}`"
            class="mb-3"
            data-test="filter-status"
          >
            {{ button.label }}
          </form-button>
        </template>

        <select-field v-model="filters.month" :options="months()" variant="rounded" data-test="filter-month" />
        <select-field v-model="filters.year" :options="years()" variant="rounded" data-test="filter-year" />
        <form-button v-if="showHistoryButton" @click="toggleListPayments" variant="primary" data-test="history-button">Histórico</form-button>
      </div>
    </div>

    <div class="overview mb-5">
      <financial-card type="overdue" :amount="overview.overdue" data-test="overview-card-overdue" />
      <financial-card type="receivable" :amount="overview.receivable"  data-test="overview-card-receivable"/>
      <financial-card type="received" :amount="overview.received"  data-test="overview-card-received"/>
    </div>

    <template v-if="activePaymentList">
      <div class="payment-list" data-test="payment-list">
        <payment-card
          v-for="payment in payments"
          :payment="payment"
          class="payment-list__card"
          :key="payment.id"
        />
      </div>

      <list-pagination
        :totalPages="totalPages"
        :currentPage="currentPage"
        @changed="handleChange"
        v-if="totalPages > 0"
      />
    </template>

  </div>
</template>

<script>
import FormButton from '@/components/form/FormButton'
import SelectField from '@/components/form/SelectField'
import FinancialCard from '@/components/financial/OverviewCard'
import PaymentCard from '@/components/financial/PaymentCard'
import ListPagination from '@/components/ListPagination'
import { monthsOptions, yearsOptions } from '@/enums/dates'
import { mapActions } from 'vuex'

export default {
  components: {
    FormButton,
    SelectField,
    FinancialCard,
    PaymentCard,
    ListPagination
  },

  props: {
    projectId: {
      type: String,
      default: undefined
    },

    listPayments: {
      type: Boolean,
      default: true
    },

    clientId: {
      type: String
    }
  },

  data () {
    return {
      overview: {
        overdue: 0,
        receivable: 0,
        received: 0
      },
      payments: [],
      totalPages: 0,
      currentPage: 1,
      monthsOptions: monthsOptions(),
      yearsOptions: yearsOptions(),
      financialCards: ['overdue', 'receivable', 'received'],
      filters: {
        year: undefined,
        month: undefined,
        status: undefined,
        projectId: this.projectId,
        clientId: this.clientId
      },
      status: [
        {
          status: 'overdue',
          label: 'Vencidos',
          variant: 'danger'
        },
        {
          status: 'receivable',
          label: 'À receber',
          variant: 'secondary'
        },
        {
          status: 'received',
          label: 'Recebidos',
          variant: 'primary'
        }
      ],
      activePaymentList: this.listPayments,
      showHistoryButton: !this.listPayments
    }
  },

  mounted () {
    this.fetchOverview()
  },

  methods: {
    ...mapActions([
      'getPaymentOverview'
    ]),

    months () {
      return [
        {
          value: undefined,
          label: 'Geral'
        },
        ...monthsOptions()
      ]
    },

    years () {
      return [
        {
          value: undefined,
          label: 'Geral'
        },
        ...yearsOptions()
      ]
    },

    async fetchOverview () {
      try {
        const { month, year, status, projectId, clientId } = this.filters

        const { data } = await this.getPaymentOverview({ month, year, status, project_id: projectId, client_id: clientId, page: this.currentPage })
        const { overview, list, total_pages: totalPages } = data.payments
        this.overview = overview
        this.payments = list.map(p => p.payment)
        this.totalPages = parseInt(totalPages)
      } catch (e) {
        this.$toast.error('Houve algo de errado, por favor tente novamente mais tarde.')
      }
    },

    activeFilterStatus (status, variant) {
      if (status === this.filters.status) {
        return variant
      }

      return `outline-${variant}`
    },

    setStatusFilter (status) {
      if (status === this.filters.status) {
        this.filters.status = undefined
        return
      }

      this.filters.status = status
    },

    handleChange (newIndex) {
      this.currentPage = newIndex
    },

    toggleListPayments () {
      this.activePaymentList = !this.activePaymentList
    }
  },

  watch: {
    filters: {
      handler () {
        this.currentPage = 1
        this.fetchOverview()
      },
      deep: true
    },

    currentPage: {
      handler () {
        this.fetchOverview()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/constants.scss';
@import '@/styles/card.scss';

.payments-overview {
  .overview-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .header-filters {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      column-gap: .5rem;
    }
  }

  .overview {
    padding: 0 30px;
    display: flex;
    justify-content: center;
    column-gap: 20px;
  }

  .payment-list {
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(auto-fill, minmax($card-list-column-min, $card-list-column-max));
    grid-gap: $card-list-grid-gap;
    margin: $card-list-margin;
    justify-items: center;

    &__card {
      width: 100%;
      align-self: center;
      justify-self: center;
    }
  }
}
</style>
