<template>
  <main class="project-list">
    <user-navbar />

    <div class="project-list__header">
      <h3 class="">Projetos</h3>

      <div class="project-list__search-options">
        <input-field
          v-model="filters.term"
          variant="rounded"
          name="project-search"
          placeholder="Busque por projetos"
          label=""
          @enter="searchProjects"
        />
        <select-field
          v-model="filters.month"
          :options="months()"
          data-test="filter-month"
          variant="rounded"
        />
        <select-field
          v-model="filters.year"
          :options="years()"
          data-test="filter-year"
          variant="rounded"
        />
        <form-button @click="searchProjects" variant="primary" class="mb-3">
          <i class="bi-search"/>
        </form-button>
      </div>

    </div>
    <div class="project-list__card-list">
      <div class="project-list__new-project-link">
        <div @click="toggleProjectUpsert">
          <i class="bi-person-plus" />
          <br />Adicionar novo projeto
        </div>
      </div>
      <project-card
        v-for="project in projectList"
        :key="project.id"
        :project="project"
        class="project-list__card"
      />
    </div>

    <list-pagination
      :totalPages="totalPages"
      :currentPage="currentPage"
      @changed="handleChange"
    />

    <project-upsert :show="projectUpsert.isVisible" @project-upsert-close="toggleProjectUpsert" />
  </main>
</template>

<script>
import UserNavbar from '@/components/UserNavbar'
import ProjectCard from '@/components/ProjectCard'
import ListPagination from '@/components/ListPagination'
import ProjectUpsert from '@/views/private/projects/ProjectUpsert'
import InputField from '@/components/form/InputField'
import SelectField from '@/components/form/SelectField'
import FormButton from '@/components/form/FormButton'
import { monthsOptions, yearsOptions } from '@/enums/dates'
import { mapActions } from 'vuex'

export default {
  components: { UserNavbar, ProjectCard, ListPagination, ProjectUpsert, SelectField, InputField, FormButton },

  data () {
    return {
      projectList: [],
      currentPage: 1,
      totalPages: 0,
      projectUpsert: {
        isVisible: false
      },
      filters: {
        year: null,
        month: null,
        term: null
      },
      monthsOptions: monthsOptions(),
      yearsOptions: yearsOptions()
    }
  },

  mounted () {
    this.listProjects()
  },

  methods: {
    ...mapActions(['listProject']),

    months () {
      return [
        {
          value: undefined,
          label: 'Geral'
        },
        ...monthsOptions()
      ]
    },

    years () {
      return [
        {
          value: undefined,
          label: 'Geral'
        },
        ...yearsOptions()
      ]
    },

    handleChange (newIndex) {
      this.currentPage = newIndex
    },

    listProjects () {
      const { term, month, year } = this.filters

      this.listProject({ page: this.currentPage, term, month, year }).then(({ data }) => {
        this.projectList = data.projects.map(p => p.project)
        this.currentPage = parseInt(data.current_page)
        this.totalPages = parseInt(data.total_pages)
      })
    },

    toggleProjectUpsert () {
      this.projectUpsert.isVisible = !this.projectUpsert.isVisible
    },

    searchProjects () {
      this.currentPage = 1
      this.listProjects()
    }
  },

  watch: {
    currentPage () {
      this.listProjects()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/constants.scss';

  .project-list {
    &__header {
      padding: $form-page-padding;
      padding-bottom: 0;
      display: flex;
      justify-content: space-between;
    }

    &__search-options {
      display: flex;
      justify-content: space-between;
      align-items: center;

      column-gap: 5px;
    }

    &__card-list {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: repeat(auto-fill, minmax($card-list-column-min, $card-list-column-max));
      grid-gap: $card-list-grid-gap;
      margin: $card-list-margin;
      justify-items: center;
    }

    &__card {
      width: 100%;
      align-self: center;
      justify-self: center;
    }

    &__new-project-link {
      display: flex;
      align-content: center;
      justify-content: center;

      div {
        font-size: 1.75em;
        color: $gray-lighter-color;
        text-align: center;
        text-decoration: none;
        padding: 1em;
        cursor: pointer;

        i {
          font-size: 2em;
        }

      }

      div:hover {
        color: $secondary-color;
      }
    }
  }
</style>
